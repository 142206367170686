export const LANG_SIGNIN = {
  signIn: 'Sign In',
  SAPPAcademy: 'SAPP Academy Operations',
  orWithEmail: 'Or with email',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot Password ?',
  notMember: 'Not a Member yet?',
  terms: 'Terms',
  contactUs: 'Contact Us',
}

export const LANG_SIDEBAR = {
  lms: 'LMS',
  dashboard: 'Dashboard',
  news: 'Tin tức',
  listNews: 'Danh sách',
  createNews: 'Tạo tin tức',
  courses: 'Courses',
  courseList: 'Course List',
  test: 'Test',
  caseStudy: 'Case Study',
  topicList: 'Item Set',
  topic: 'Item Set Detail',
  questionBank: 'Question Bank',
  dictionary: 'Dictionary',
  supportCenter: 'Trung tâm hỗ trợ',
  supportOverview: 'Báo cáo',
  supportTickets: 'Tickets',
  supportFAQs: 'FAQs',
  notifications: 'Notifications',
  sendNotifications: 'Gửi thông báo',
  listNotifications: 'Notification List',
  listGroups: 'Group List',
  staffs: 'Staffs',
  listStaffs: 'Staff List ',
  createStaffs: 'Create Staff',
  students: 'Students',
  listStudents: 'Student List',
  createStudents: 'Create Student',
  teachers: 'Teachers',
  listTeacher: 'Teacher List',
  createTeacher: 'Create Teacher',
  admin: 'Admin',
  operations: 'Operations',
  roles: 'Roles',
  permissions: 'Permissions',
  setting: 'Setting',
  pro: 'Pro',
  myProfile: 'My Profile',
  mode: 'Mode',
  light: 'Light',
  dark: 'Dark',
  system: 'System',
  signOut: 'Sign Out',
  importLog: 'Import Report',
  createStory: 'Case Study Detail',
  listStories: 'Case Studies',
  listCertificates: 'Certificates',
  commingSooon: 'Comming soon',
  addItemSet: 'Add Item Set',
  addSingleQuestion: 'Add single question',
  questionList: 'Question List',
  supporterGroupList: 'Supporter Groups',
  marketing: 'Marketing',
  eventTest: 'Event Test List',
  createEvent: 'Create Event Test',
  allParticipant: 'Participants',
  facilityList: 'Facility List',
  roomList: 'Classroom List',
}

export const LANG_COURSES = {
  addCourses: 'Create Course',
  code: 'CODE',
  courseName: 'COURSE NAME',
  subjects: 'SUBJECTS',
  action: 'ACTION',
  pleaseSelect: 'Please Select',
  selectCourse: 'Select Course',
  selectSection: 'Select Section',
  selectSubsection: 'Select Subsection',
  seclectUnit: 'Seclect Unit',
  seclectActivity: 'Seclect Activity',
  courseList: 'Course List',
}
export const LANG_CLASSES = {
  addClass: 'Create Class',
  importClass: 'Import Class',
  className: 'Class Name',
}

export const LANG_ENTRANCE_TEST = {
  addEntranceTest: 'Create Entrance Test',
  entranceTestName: 'Entrance Test Name',
  addQuestion: 'Add Question',
  LIST_TEST: 'Entrance Tests',
  addParticipant: 'Add Participants',
}

export const LANG_COURSE_CATEGORY = {
  categoryName: 'CATEGORY NAME',
  description: 'DESCRIPTION',
  action: 'ACTION',
}

export const LANG_COURSE_SUBJECT = {
  SUBJECT_NAME: 'SUBJECT NAME',
  CODE: 'CODE',
  PROGRAM: 'PROGRAM',
  ACTION: 'ACTION',
  UPDATED_AT: 'UPDATED AT',
}

export const BUTTON_TEXT = {
  SAVE: 'Save',
  SEARCH: 'Search',
  CANCEL: 'Cancel',
}

export const LANG_PLACEHOLDER = {
  FULLNAME: 'Full Name',
}

export const LANG_CERTIFICATES = {
  addTemplate: 'Create Template',
  importCertificate: 'Import Certificate',
  CertificatesName: 'Certificate Name',
}

export const DESCRIPTION_POPUPCONFIRM = 'Bạn có chắc chắn muốn hủy không?'
export const DELETE_POPUPCONFIRM = 'Bạn có chắc chắn muốn xóa không?'
export const DUPLICATE_POPUPCONFIRM = 'Bạn có chắc chắn muốn sao chép không?'
export const CONFIRM_STATUS_CHANGE = 'Bạn có chắc chắn muốn đổi trạng thái không?'
export const ACTIVE_POPUPCONFIRM = 'Bạn có muốn kích hoạt chức năng không?'
export const DEACTIVE_POPUPCONFIRM = 'Bạn có muốn dừng kích hoạt chức năng không?'
export const REFRESH_POPUPCONFIRM = 'Bạn có muốn làm mới chức năng không?'

export const LANG_SHOP = {
  listEvent: 'List Events',
}

export const LANG_USER = {
  TEST_ACCOUNT: 'Test Account',
  STUDENT_ID: 'Student ID',
  EMPLOYEE_ACCOUNT: 'Employee Account',
  EMPLOYEE_CODE: 'Employee Code',
}

export const LANG_NEWS = {
  searchNews: 'Search news...',
  submit: 'Submit',
  selected: 'Selected',
  deleteSelected: 'Delete Selected',
  export: 'Export',
  addNews: 'Add news',
  thumbnail: 'Thumbnail',
  selectPublishing: 'Select publishing date and time',
  productDetails: 'Product Details',
  guidelineProduct: 'Add product to a category.',
  createCategory: 'Create new category',
  tags: 'Tags',
  addTags: 'Add tags to a product.',
  weeklySales: 'Weekly Sales',
  notData: 'No data available. Sales data will begin capturing once product has been published.',
  productTemplate: 'Product Template',
  general: 'General',
  productName: 'Product Name',
  descriptionProductName: 'A product name is required and recommended to be unique.',
  description: 'Description',
  media: 'Media',
  dropFiles: 'Drop files here or click to upload.',
  upload: 'Upload up to 10 files',
  descriptionUpload: 'Set the product media gallery.',
  pricing: 'Pricing',
  basePrice: 'Base Price',
  descriptionPrice: 'Set the product price.',
  discountType: 'Discount Type',
  noDiscount: 'No Discount',
  percentage: 'Percentage %',
  fixedPrice: 'Fixed Price',
  discountPercentage: 'Set Discount Percentage',
  descriptionDiscountPercentage: 'Set a percentage discount to be applied on this product.',
  discountedPrice: 'Fixed Discounted Price',
  descriptionDiscountedPrice:
    'Set the discounted product price. The product will be reduced at the determined fixed price',
  taxClassName: 'Tax className',
  VATAmount: 'VAT Amount (%)',
  descriptionVATAmount: 'Set the product VAT about.',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
}

export const MY_COURSES = {
  explanations: 'Explanations',
  hotline: '19002225',
}

export const LANG_GRADE_PAGE = {
  total: 'Total',
  multipleChoice: 'Multiple Choice',
  constructed: 'Constructed',
  chooseQuestToView: 'Please choose one question to review or grade.',
  cantFinish: 'Oops, you can’t finish',
  errorOcurred: 'An error occurred. Please try again later.',
  mustGradeQuestionsBefore: 'You must grade questions ',
  mustGradeQuestionsAfter: ' before finish.',
  continueGrade: 'Continue Grading',
  tryAgain: 'Try Again',
  close: 'Close',
}
