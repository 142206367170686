import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import { ITabs } from 'src/type'
import { replaceValueAll } from 'src/utils/string'
import { cleanParamsAPI } from 'src/utils'
import useChecked from 'src/hooks/use-checked'
import { EntranceApi } from 'src/apis/entrance-test'
import {
  FILTER_SELECTALL_SORTBY,
  FILTER_SORT_RESULT,
  FILTER_SOURCE,
  PageLink,
  SORT_BY,
} from 'src/constants'
import { LANG_ENTRANCE_TEST, LANG_SIDEBAR } from 'src/constants/lang'
import { IETest, IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import { EventTestAPI } from 'src/apis/event-test'
import ParticipantEventTable from 'src/components/event-test/participants/ParticipantEventTable'
import AddParticipantEvent from 'src/components/event-test/participants/AddParticipantEvent'
import { UserExportHelper } from 'src/helper/export'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'
// import AddParticipant from '../../../components/entrance-test/participant/AddParticipant'

const { Option } = Select
const fieldNames = [
  'text',
  'status',
  'level',
  'source',
  'sortType',
  'fromDate',
  'toDate',
  'sortByRatioScore',
]
const initialValues = {
  text: '',
  status: '',
  level: '',
  source: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  sortByRatioScore: '',
}
const ParticipantEventTest = () => {
  const { id } = useParams()
  const [participantList, setParticipantList] = useState<IParticipantInTestList>()
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const [dateField, setDateField] = useState<string>('updated_at')
  const [entranceTest, setEntranceTest] = useState<IETest>()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: LANG_SIDEBAR.lms,
    },
    {
      link: PageLink.EVENT_TEST,
      title: 'Event Tests',
    },
    {
      link: '',
      title: `Participants of ${entranceTest?.name ?? ''}`,
    },
  ]

  const queryParams = {
    text: searchParams.get('text') ?? '',
    status: searchParams.get('status') ?? '',
    level: searchParams.get('level') ?? '',
    source: searchParams.get('source') ?? '',
    sortType: searchParams.get('sortType') as string,
    sortByRatioScore: searchParams.get('sortByRatioScore') ?? '',
    // fromDate: searchParams.get('fromDate') as unknown as Date,
    // toDate: searchParams.get('toDate') as unknown as Date,

    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const { control, getValues, reset, setValue, watch } = useForm()

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      text: getValues('text') ?? '',
      status: replaceValueAll(getValues('status')) ?? '',
      level: replaceValueAll(getValues('level')) ?? '',
      source: replaceValueAll(getValues('source')) ?? '',
      sortType: replaceValueAll(getValues('sortType')) ?? '',
      sortByRatioScore: getValues('sortByRatioScore') || undefined,
      // fromDate: formatDate(getValues('fromDate')) ?? '',
      // toDate: formatDate(getValues('toDate')) ?? '',
      page_index: currenPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const filterParams = cleanParamsAPI({
    text: queryParams.text?.trim() ?? '',
    status: queryParams.status ?? '',
    level: queryParams.level ?? '',
    source: queryParams.source ?? '',
    sortType: queryParams.sortType,
    sortByRatioScore: queryParams.sortByRatioScore || undefined,
    // fromDate: queryParams.fromDate,
    // toDate: queryParams.toDate,
  })

  const getParams = (
    text: string,
    status: string,
    level: string,
    source: string,
    sortType: string,
    sortByRatioScore: string
    // fromDate?: string | Date,
    // toDate?: string | Date
  ) => ({
    text,
    status,
    level,
    source,
    sortType,
    sortByRatioScore,
    // fromDate,
    // toDate,
    dateField,
  })

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<IParticipantInTest>(
    participantList?.data
  )

  const getQuiz = async () => {
    if (!id) return
    const res = await EntranceApi.getEntranceTestDetail(id)
    setEntranceTest(res.data)
  }

  const fetchParticipantList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      if (!id) return
      const res = await EventTestAPI.getParticipantEventTest({
        id,
        page_index: currentPage || 1,
        page_size: pageSize || 10,
        params: {
          ...(params ?? {}),
          filter_type: 'SELECTED',
        },
      })
      setParticipantList(res.data)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  /**
   * handle Filter By params
   */
  const onSubmit = () => {
    // const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    // const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text') ?? '',
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('level')),
        replaceValueAll(getValues('source')),
        replaceValueAll(getValues('sortType')),
        getValues('sortByRatioScore')
        // getValues('fromDate')
        //   ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        //   : '',
        // getValues('toDate')
        //   ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        //   : ''
      )
    )

    setLoading(true)
    fetchParticipantList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    setDateField('')
    setLoading(true)
    fetchParticipantList(1, 10)
    navigate(`${PageLink.EVENT_TEST}/${id}/participants`)
  }

  useEffect(() => {
    getQuiz()
    fetchParticipantList(1, 10)
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)

  const onClickExportPermission = async () => {
    setLoadingExport(true)
    await UserExportHelper.exportToXlsxEvent(
      id,
      watch('text'),
      watch('source'),
      watch('sortByRatioScore')
      // watch('fromDate'),
      // watch('toDate')
    )
    setLoadingExport(false)
  }

  const { profileMe } = useUserContext()
  const allowRenderGetEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST
  )
  const allowRenderGetParticipant = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST_PARTICIPANTS
  )
  const allowRenderCreateEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST
  )
  const allowRenderEditEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST
  )

  const allowRenderAddStudentParticipant = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.ADD_STUDENT_EVENT_TEST
  )

  return (
    <PageLayouts
      pageTitle={`Participants of ${entranceTest?.name ?? ''}`}
      breadcrumbs={breadcrumbs}
    >
      <div className='card'>
        <div className='px-9 border-0 pt-6'>
          <div className=''>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <div className='card-title justify-content-center mx-0'>
                  <HookFormTextField
                    control={control}
                    name='text'
                    placeholder='Search participant name'
                    defaultValue={queryParams?.text}
                    onSubmit={onSubmit}
                    isListScreen
                  />
                </div>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='source'
                  placeholder='Source'
                  control={control}
                  defaultValue={queryParams?.source ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SOURCE.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='sortByRatioScore'
                  placeholder='Sort Result'
                  control={control}
                  defaultValue={queryParams?.sortByRatioScore ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SORT_RESULT.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              {/* <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  isListScreen
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  isListScreen
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6 px-9'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <div>
            {(allowRenderGetEventTest ||
              allowRenderCreateEventTest ||
              allowRenderEditEventTest) && (
              <ButtonIconPrimary
                iconName='file-down'
                title='Export Result'
                loading={loadingExport}
                onClick={onClickExportPermission}
                size='small'
                iconType='outline'
              />
            )}
            {(allowRenderGetParticipant || allowRenderAddStudentParticipant) && (
              <ButtonIconPrimary
                iconName='plus'
                title={LANG_ENTRANCE_TEST.addParticipant}
                className='ms-3'
                onClick={() => setOpen(true)}
                size='small'
              />
            )}
          </div>
        </div>
        <ParticipantEventTable
          participantList={participantList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchParticipantList={fetchParticipantList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <AddParticipantEvent
          open={open}
          setOpen={setOpen}
          id={id ?? ''}
          fetchParticipantPage={() => fetchParticipantList(1, 10)}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(ParticipantEventTest)
