import { isNull } from 'lodash'

// Constants
export const PREFIX = 'h_'

/**
 * Retrieves the selected nodes within a given range and calculates the `begin` and `end` indices based on span element IDs.
 * This function is specifically designed to work with spans that have IDs prefixed with a provided string.
 *
 * @param {string} prefix - The prefix used to identify the span elements (e.g., "h_" for span IDs like "h_0", "h_1").
 *
 * @returns {object | null} - Returns an object containing the selected nodes, the start (`begin`) and end (`end`) indices,
 * or `null` if no valid selection is made. The `begin` and `end` indices represent the range of the selection:
 * - `selectedNodes`: Array of elements within the selection range.
 * - `begin`: The start index (inclusive) based on the span ID.
 * - `end`: The end index (exclusive) based on the span ID.
 */
function getSelectedNodesAndRange(prefix: string) {
  const selection = window.getSelection()

  // Exit if there is no valid text selection
  if (!selection || selection.rangeCount === 0 || selection.type !== 'Range') return null

  const range = selection.getRangeAt(0)
  const selectedNodes: HTMLElement[] = []

  const walker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT, {
    acceptNode(_node) {
      return NodeFilter.FILTER_ACCEPT
    },
  })

  while (walker.nextNode()) {
    const node = walker.currentNode as HTMLElement
    if (range.intersectsNode(node)) {
      selectedNodes.push(node)
    }
  }

  // Exit if no nodes are found
  if (selectedNodes.length === 0) return null

  const validSpans = selectedNodes.filter((node) => node.tagName === 'SPAN')

  if (validSpans.length === 0) return null

  const begin = parseInt(validSpans[0].id.replace(prefix, ''), 10)
  const end = parseInt(validSpans[validSpans.length - 1].id.replace(prefix, ''), 10) + 1

  if (isNaN(begin) || isNaN(end)) {
    return null
  }

  return { selectedNodes: validSpans, begin, end }
}

/**
 * Function to wrap each character in a span element and assign an ID to each span.
 *
 * @param {string} contentSelector - The CSS selector for the content area that contains the text to index.
 */
export function indexCharacters(contentSelector: string) {
  let index = 0

  /**
   * Recursive function to walk through each node and wrap text nodes in a span element.
   *
   * @param {Node} node - The current node to be processed.
   */
  function walk(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const characters = node.nodeValue ? node.nodeValue.split('') : []
      const fragment = document.createDocumentFragment()

      characters.forEach((char: string) => {
        const span = document.createElement('span')
        span.textContent = char
        span.setAttribute('id', `${PREFIX}${index++}`)
        fragment.appendChild(span)
      })

      return node.parentNode?.replaceChild(fragment, node)
    }

    for (let child of Array.from(node.childNodes)) {
      if (!(child as HTMLElement).id?.startsWith(PREFIX)) {
        walk(child)
      }
    }
  }

  const content = document.querySelector(contentSelector)
  if (content) walk(content)
}

/**
 * Function to add a highlight to selected nodes.
 * It wraps selected text in a span element and applies a class for highlighting.
 *
 * @param {string} className - The class to be applied to the highlighted elements.
 * @returns {object | null} - Returns the begin and end range of the selected text, or null if no valid selection is made.
 */
export function highlightSelection(className: string) {
  const result = getSelectedNodesAndRange(PREFIX)
  if (!result) return null

  const { selectedNodes, begin, end } = result

  selectedNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent && node.textContent.trim() !== '') {
      const span = document.createElement('span')
      span.textContent = node.textContent
      node.parentNode?.replaceChild(span, node)
    }
    node.classList.add(className)
  })

  return { begin, end }
}

/**
 * Function to remove the highlight from a given range in the DOM.
 *
 * @param {number} begin - The start index of the highlighted range.
 * @param {number} end - The end index of the highlighted range.
 */
export function removeHighlightFromDOM(begin: number, end: number, index: string) {
  // Loop through the range from begin to end to remove highlights
  const parentElement = document.getElementById(`editor_area_${index}`)
  if (!parentElement) {
    console.error('Parent element not found:', `editor_area_${index}`)
    return
  }

  for (let i = begin; i < end; i++) {
    const spanWrapper = parentElement.querySelector(`#${PREFIX}${i}`)

    if (spanWrapper) {
      // Remove any highlight classes
      spanWrapper.classList.remove('sapp-comment-highlight', 'sapp-grading-highlight')

      // Find and remove the inner 'sapp-grade-tooltip' span only
      const innerTooltipSpan = spanWrapper.querySelector('.sapp-grade-tooltip')
      if (innerTooltipSpan) {
        innerTooltipSpan.remove()
      }
    }
  }
}

/**
 * Restores highlights on the elements based on a list of grading information.
 *
 * @param {Array} listGrades - The list of grading objects containing information about highlights.
 * @param {string} index - The index for identifying the parent element.
 */
export const restoreHighlights = (listGrades: any[], index: string) => {
  const parentElement = document.getElementById(`editor_area_${index}`)

  // Exit if the parent element is not found
  if (!parentElement) {
    console.error(`Parent element not found with id: editor_area_${index}`)
    return
  }

  // Loop through each grade and apply the necessary highlights
  ;(listGrades || []).forEach((item) => {
    if (item?.begin === undefined || item?.end === undefined) return

    const { begin, end, grade, comment } = item
    let className = ''

    // Determine which class to apply based on the item properties
    if (grade >= 0 && !isNull(grade) && comment) {
      className = 'sapp-comment-highlight sapp-grading-highlight'
    } else if (comment && isNull(grade)) {
      className = 'sapp-comment-highlight'
    } else {
      className = 'sapp-grading-highlight'
    }

    let lastSpan: HTMLElement | null = null

    // Loop through the selected range and apply the class
    for (let i = begin; i < end; i++) {
      const span = parentElement.querySelector(`#${PREFIX}${i}`)

      if (span) {
        span.classList.remove('sapp-comment-highlight', 'sapp-grading-highlight')

        className.split(' ').forEach((cls) => {
          if (!span.classList.contains(cls)) {
            span.classList.add(cls)
          }
        })
        lastSpan = span as HTMLElement
      }
    }

    if (lastSpan) {
      const existingTooltip = lastSpan.querySelector('.sapp-grade-tooltip')

      if (!isNull(grade)) {
        // If grade is not null, update or add the tooltip
        if (existingTooltip) {
          if (existingTooltip.textContent !== `${formatGrade(grade)}`) {
            existingTooltip.textContent = `${formatGrade(grade)}`
          }
        } else {
          const tooltip = document.createElement('span')
          tooltip.className = 'sapp-grade-tooltip'
          tooltip.textContent = `${formatGrade(grade)}`
          lastSpan.appendChild(tooltip)
        }
      } else {
        // If grade is null, remove the tooltip
        if (existingTooltip) {
          existingTooltip.remove()
        }
      }
    }
  })
}

/**
 * Retrieves the begin and end range from the current selection.
 *
 * @param {string} PREFIX - The prefix for identifying elements.
 * @returns {object | null} - Returns an object with `begin` and `end` or null if no valid selection is made.
 */
export function getSelectionRange(PREFIX: string) {
  const result = getSelectedNodesAndRange(PREFIX)
  if (!result) return null

  const { begin, end } = result
  return { begin, end }
}

// Hàm thay thế style text-align: center thành style text-align: -webkit-center trong chuỗi HTML
export const replaceTextAlignCenterToWebKitCenter = (htmlString: string | undefined) => {
  if (!htmlString) return ''
  // Sử dụng biểu thức chính quy để thay thế tất cả các trường hợp text-align: center
  return htmlString.replace(/text-align:\s*center/g, 'text-align: -webkit-center')
}

export const formatGrade = (grade: number | undefined | null): number | string => {
  if (grade === undefined || grade === null) {
    return 0
  }

  if (Number.isInteger(grade)) {
    return grade
  }

  const gradeStr = grade.toString()
  const decimalIndex = gradeStr.indexOf('.')

  if (decimalIndex === -1) {
    return grade
  }

  return gradeStr.substring(0, decimalIndex + 3)
}

export function convertFractionalScore(score: number): number {
  // Lấy phần thập phân của số
  const decimal = score % 1

  // Lấy phần nguyên của số
  const integer = Math.floor(score)

  // Nếu phần thập phân nhỏ hơn 0.25
  if (decimal < 0.25) {
    return integer
  }
  // Nếu phần thập phân lớn hơn hoặc bằng 0.25 và nhỏ hơn 0.5
  else if (decimal >= 0.25 && decimal < 0.5) {
    return integer + 0.25
  }
  // Nếu phần thập phân lớn hơn hoặc bằng 0.5 và nhỏ hơn 0.75
  else if (decimal >= 0.5 && decimal < 0.75) {
    return integer + 0.5
  }
  // Nếu phần thập phân lớn hơn hoặc bằng 0.75
  else {
    return integer + 0.75
  }
}
