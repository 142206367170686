function getDirtyValues<DirtyFields extends Record<string, unknown>, Values extends Record<keyof DirtyFields, unknown>>(
    dirtyFields: DirtyFields,
    values: Values
  ): Partial<Values> {
    const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
      // Unsure when RFH sets this to `false`, but omit the field if so.
      if (!dirtyFields[key]) return prev;
  
      return {
        ...prev,
        [key]:
          typeof dirtyFields[key] === 'object'
            ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
            : values[key],
      };
    }, {} as Partial<Values>);
  
    return dirtyValues;
  }

  export default getDirtyValues