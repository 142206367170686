import { ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { useConfirm } from 'src/hooks/use-confirm'
import './SappModal.scss'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { Toaster } from 'react-hot-toast'
interface IProps {
  open: boolean | undefined
  children: ReactNode
  handleClose?: () => void
  title: string
  handleCancel?: () => void
  handleSubmit?: () => void
  loading?: boolean
  cancelButtonCaption?: string | undefined
  okButtonCaption?: string | undefined
  dialogClassName?: string
  centered?: boolean
  classNameBody?: string
  showFooter?: boolean
  customFooter?: ReactNode
  enforceFocus?: boolean
  confirmOnclose?: boolean | string[]
  customTitle?: (title: string) => ReactNode
  hideHeader?: boolean
  footerEnd?: boolean
  classBody?: string
  contentClassName?: string
  showClassHeightBody?: boolean
  classNameContent?: string
  disabled?: boolean
  classNameFooter?: string
  confirmOnSubmit?: boolean
  confirmSubmitMessage?: string
  showCloseButton?: boolean
  disableInnerScroll?: boolean //to disable inner scroll for body child
  classNameHeader?: string
  showIconDelete?: boolean
}

const SappModal = ({
  children,
  open,
  handleClose,
  title,
  handleCancel,
  handleSubmit,
  loading,
  cancelButtonCaption,
  okButtonCaption,
  dialogClassName,
  centered = false,
  classNameBody,
  showFooter = true,
  customFooter,
  confirmOnclose = false,
  enforceFocus = true,
  customTitle,
  hideHeader = false,
  footerEnd = false,
  classBody,
  contentClassName,
  showClassHeightBody = true,
  classNameContent,
  disabled,
  classNameFooter,
  confirmOnSubmit = false,
  confirmSubmitMessage = '',
  showCloseButton = true,
  disableInnerScroll = false,
  classNameHeader,
  showIconDelete = true,
}: IProps) => {
  // const [modalHeight, setModalHeight] = useState(0)
  const { confirm, contextHolder } = useConfirm()

  // useEffect(() => {
  //   if (open) {
  //     const modalContent = document.getElementById('modal-body')
  //     if (modalContent) {
  //       const newHeight = modalContent.clientHeight
  //       setModalHeight(newHeight)
  //     }
  //   }
  // }, [open])

  const onClose = (action?: () => void) => {
    if (confirmOnclose) {
      let body = [DESCRIPTION_POPUPCONFIRM]
      if (Array.isArray(confirmOnclose)) {
        body = confirmOnclose
      }
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body,
        onClick: () => {
          action && action()
        },
      })
    } else {
      action && action()
    }
  }
  const onSubmit = (action?: () => void) => {
    if (confirmSubmitMessage) {
      let body = [confirmSubmitMessage]
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body,
        onClick: () => {
          action && action()
        },
      })
    } else {
      action && action()
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        dialogClassName={`modal-dialog overflow-hidden ${dialogClassName}`}
        centered={centered}
        show={open}
        onHide={() => onClose(handleClose)}
        autoFocus
        restoreFocus={false}
        enforceFocus={enforceFocus}
        contentClassName={contentClassName ?? ''}
      >
        {/* start:: header modal */}
        {!hideHeader && (
          <div
            className={`modal-header justify-content-between ${
              customTitle ? 'align-items-start' : 'align-items-center'
            } ${classNameHeader ?? ''}`}
          >
            {customTitle ? (
              customTitle(title)
            ) : (
              <h2 className='sapp-modal-header-title mb-0'>{title}</h2>
            )}
            {showIconDelete && (
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-icon_close'
                onClick={() => onClose(handleClose)}
              >
                <i className={`ki-outline ki-cross fs-1 ${customTitle ? 'mt-n1' : ''}`} />
              </div>
            )}
          </div>
        )}
        {/* end:: header modal */}

        {/* start:: body modal */}
        <div className={classBody || 'p-10 modal-body'} id='modal-body'>
          <div
            className={`${classNameBody ?? ''} ${!disableInnerScroll && 'scroll-y'} ${
              showClassHeightBody ? 'sapp-m-h-450px' : ''
            }`}
          >
            <div
              className={`${
                classNameContent ? classNameContent : ''
              } sapp-modal-body-content w-100 `}
            >
              {children}
            </div>
          </div>
        </div>
        {/* end:: body modal */}
        {showFooter && (
          <div
            className={`modal-footer ${footerEnd ? 'flex-end' : 'flex-center'} ${
              classNameFooter ?? ''
            }`}
          >
            <SAPPDialogButtonsCancelSubmit
              cancelClick={() => {
                onClose(handleCancel || handleClose)
              }}
              cancelButtonCaption={cancelButtonCaption ?? ''}
              okButtonCaption={okButtonCaption ?? ''}
              okOnClick={() => onSubmit(handleSubmit)}
              className='justify-content-center d-flex m-0'
              loading={loading}
              disabled={disabled}
            />
          </div>
        )}
        {customFooter && <Modal.Footer className={classNameFooter}>{customFooter}</Modal.Footer>}
        <Toaster
          toastOptions={{ className: 'sapp-mw-1000px custom-toast', style: { zIndex: 10000 } }}
        />
      </Modal>
    </>
  )
}

export default SappModal
