import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton } from 'antd'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import {
  PageLink,
  VALIDATE_FIELD_MAX_LENGTH,
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATION_FIELD,
  VALIDATION_MIN_EQUAL,
} from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'
import { LIST_TYPE, TEST_TYPE } from '../shared/Interfaces'
import {
  ATTEMPT,
  GRADED_ASSIGNMENT,
  GRADING_PREFERENCE,
  PASS_POINT,
  QUIZ_GRADING_METHOD,
  TEST_MODE,
  TYPE_OF_TEST,
} from '../shared/consts'
import '../shared/styles/Course.scss'
import ChooseQuestionsModal from './ChooseQuestionsModal'
import './TestComponent.scss'
import QuizQuestionTable from './TestQuestionTable'
import SappLabel from 'src/components/base/label/SappLabel'
import { isNull, isUndefined, round, sum } from 'lodash'
import ErrorMessage from 'src/common/ErrorMessage'
import { QuizState } from 'src/type/quiz'

type IProps = {
  type: TEST_TYPE
  id: string
  testFor: string
  customOnSubmit?: any
  customOnCancel?: any
  forTab?: boolean
  oldData?: any
  buttonSaveTitle?: string
  editId?: string
  CancelSubmitBtnClass?: string
  editOnlyName?: boolean
  loading?: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  isCreateEditLayout?: boolean
}

interface IQuiz {
  name: string
  status: string
  type: string
  testMode: string
  gradedAssignment: string
  time: {
    hours: string
    mins: string
  }
  gradingPreference: string
  passPoint: {
    type: string
    value: string
  }
  attempt: {
    type: string
    value: string
  }
  number_of_multiple_choice_questions: string
  number_of_essay_questions: string
  // emptyMultiple?: any
  emptyConstructed?: any
  quiz_type?: string
  get_point_questions_multi?: any
  get_point_questions_constructed?: any
  manualGrading?: string
}
export interface TestChildRef {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
  onCancel: () => void
}

const defaultValues = {
  name: '',
  status: '',
  type: TYPE_OF_TEST.multipleChoice.value,
  testMode: TEST_MODE.tutor.value,
  gradedAssignment: GRADED_ASSIGNMENT.no.value,
  time: {
    hours: '',
    mins: '',
  },
  gradingPreference: GRADING_PREFERENCE.each.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
  number_of_multiple_choice_questions: '',
  number_of_essay_questions: '',
  question_error: '',
  manualGrading: 'AUTO',
}
/**
 *
 * @description Form quiz
 * @param {IProps} {
 *   type,
 *   id,
 *   testFor,
 *   customOnSubmit,
 *   forTab,
 *   customOnCancel,
 *   oldData,
 *   buttonSaveTitle = 'Save',
 *   editId,
 * }
 * @return {*}
 */
const TestComponent = forwardRef<TestChildRef, IProps>(
  (
    {
      type,
      id,
      testFor,
      customOnSubmit,
      forTab,
      customOnCancel,
      oldData,
      buttonSaveTitle = 'Save',
      editId,
      CancelSubmitBtnClass = 'justify-content-end d-flex',
      editOnlyName,
      loading,
      setLoading,
      isCreateEditLayout,
    },
    ref
  ) => {
    const navigate = useNavigate()
    const { confirm, contextHolder } = useConfirm()
    const params = useParams()
    const { getCourseDetail, setIdChild } = useCourseContext()
    const [openChooseQuestion, setOpenChooseQuestion] = useState<boolean | LIST_TYPE>(false)
    const [questionsMultiple, setQuestionsMultiple] = useState<IQuestion[]>([])
    const [questionsConstructed, setQuestionsConstructed] = useState<IQuestion[]>([])
    const [totalQuestionsMultiple, setTotalQuestionsMultiple] = useState<number>(0)
    const [totalQuestionsConstructed, setTotalQuestionsConstructed] = useState<number>(0)
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [skeleton, setSkeleton] = useState<boolean>(false)
    const [testDetail, setTestDetail] = useState<any>()

    const storeFormData = useRef<
      IQuiz & { multiple_choice_questions?: any[]; constructed_questions?: any[] }
    >({ ...defaultValues })

    const addQuestioned = useRef<{
      multiple: boolean
      constructed: boolean
    }>({ multiple: false, constructed: false })

    const validationSchema = z.object({
      name: !forTab
        ? z
            .string({ required_error: VALIDATION_FIELD })
            .trim()
            .min(1, VALIDATION_FIELD)
            .max(1000, VALIDATE_FIELD_MAX_LENGTH('Test name', 1000))
        : z.string().max(1000, VALIDATE_FIELD_MAX_LENGTH('Test name', 1000)).optional(),
      type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
      testMode: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
      gradedAssignment: z
        .string({ required_error: VALIDATION_FIELD })
        .trim()
        .min(1, VALIDATION_FIELD),
      time: z.object({
        hours: z
          .string()
          .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
          .refine(
            (val) => {
              if (
                !Number(val) &&
                !Number(getValues('time.mins')) &&
                getValues('testMode') === TEST_MODE.timed.value
              ) {
                return false
              }
              return true
            },
            { message: VALIDATION_FIELD }
          )
          .optional(),
        mins: z
          .string()
          .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
          .refine((val) => {
            if (
              !Number(val) &&
              !Number(getValues('time.hours')) &&
              getValues('testMode') === TEST_MODE.timed.value
            ) {
              return false
            }
            return true
          }, VALIDATION_FIELD)
          .optional(),
      }),
      gradingPreference: z
        .string({ required_error: VALIDATION_FIELD })
        .trim()
        .min(1, VALIDATION_FIELD),
      passPoint: z.object({
        type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
        value: z
          .string()
          .trim()
          .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
          .superRefine((arg: any, ctx) => {
            if (getValues('passPoint.type') === PASS_POINT.manual.value) {
              if (!Number(arg) || Number(arg) > 100) {
                ctx.addIssue({
                  message: VALIDATE_PASS_POINT,
                  code: 'custom',
                })
              }
            }
            return true
          })
          .optional(),
      }),
      attempt: z.object({
        type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
        value: z
          .string()
          .trim()
          .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
          .refine(
            (val) => {
              if (getValues('attempt.type') === ATTEMPT.limited.value && Number(val) < 1) {
                return false
              }
              return true
            },
            { message: VALIDATION_MIN_EQUAL(1) }
          )
          .optional(),
      }),
      number_of_multiple_choice_questions: z
        .string()
        .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
        // .superRefine((arg: any, ctx) => {
        //   // if (!Number(arg) && getValues('type') === TYPE_OF_TEST.mix.value) {
        //   //   ctx.addIssue({
        //   //     message: VALIDATE_QUESTIONS,
        //   //     code: 'custom',
        //   //   })
        //   // }
        //   if (
        //     getValues('type') === TYPE_OF_TEST.mix.value &&
        //     questionsMultiple.length > 0 &&
        //     questionsMultiple.length !== (Number(arg) || 0)
        //   ) {
        //     ctx.addIssue({
        //       message: 'The number of questions and the number in the list are not the same',
        //       code: 'custom',
        //     })
        //   }
        // })
        .optional(),
      number_of_essay_questions: z
        .string()
        .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
        .superRefine((arg: any, ctx) => {
          // if (!Number(arg) && getValues('type') === TYPE_OF_TEST.mix.value) {
          //   ctx.addIssue({
          //     message: VALIDATE_QUESTIONS,
          //     code: 'custom',
          //   })
          // }
          // if (
          //   getValues('type') === TYPE_OF_TEST.mix.value &&
          //   questionsConstructed.length > 0 &&
          //   questionsConstructed.length !== (Number(arg) || 0)
          // ) {
          //   ctx.addIssue({
          //     message: 'The number of questions and the number in the list are not the same',
          //     code: 'custom',
          //   })
          // }
        })
        .optional(),
      quiz_type: z.string().optional(),
      manualGrading: z.string().optional(),
    })

    const {
      handleSubmit,
      control,
      reset,
      setValue,
      getValues,
      watch,
      trigger,
      clearErrors,
      setError,
      formState: { errors, isSubmitted },
    } = useForm<any>({
      resolver: zodResolver(validationSchema),
      mode: 'onSubmit',
      defaultValues,
    })

    const watchType = watch('type')
    const watchPassPoint = watch('passPoint')
    const watchAttempt = watch('attempt')
    const watchTestMode = watch('testMode')
    const watchNumberOfEssayQuestions = watch('number_of_essay_questions')
    const watchNumberOfMultipleChoiceQuestions = watch('number_of_multiple_choice_questions')
    const watchQuizType = watch('quiz_type')
    const watchGradeAssignment = watch('gradedAssignment')

    /**
     * @description Lấy data từ API set vào form
     * @param {*} data
     * @return {*}  {typeof defaultValues}
     */
    useLayoutEffect(() => {
      if (editId) {
        setSkeleton(true)
        ;(async () => {
          try {
            const response = await CoursesAPI.getQuizById(editId)
            if (!response.success) {
              throw new Error('Not found!')
            }
            setTestDetail(response.data)
            setTotalQuestionsMultiple(response?.data?.multiple_choice_multiplier)
            setTotalQuestionsConstructed(response?.data?.essay_multiplier)
            const formData = convertResultToFormValues(response.data)
            Object.entries(formData)?.forEach(([key, value]) => {
              const customSetValue = setValue as any
              customSetValue(key, value)
              storeFormData.current[key as keyof IQuiz] = value
            })

            setValue('manualGrading', response?.data?.grading_method)

            storeFormData.current['constructed_questions'] = response.data.constructed_questions
            storeFormData.current['multiple_choice_questions'] =
              response.data.multiple_choice_questions
            setCanEdit(!(response?.data?.state as QuizState)?.condition?.can_edit_quiz?.other)
          } catch (error: any) {
            if (error?.response?.status === 422) {
              toast.error('Quiz not found!')
            }
            navigate(`${PageLink.COURSE_FULL}/${params.course_id}`)
            if (params?.course_id) {
              getCourseDetail(params?.course_id)
            }
          } finally {
            setSkeleton(false)
          }
        })()
      } else {
        setValue('quiz_type', type?.toUpperCase())
      }
      return () => {
        setQuestionsMultiple([])
        setQuestionsConstructed([])
        reset(defaultValues)
      }
    }, [params, editId])

    useEffect(() => {
      if (oldData) {
        setTotalQuestionsMultiple(oldData?.multiple_choice_multiplier)
        setTotalQuestionsConstructed(oldData?.essay_multiplier)
        const formData = convertResultToFormValues(oldData)
        Object.entries(formData)?.forEach(([key, value]) => {
          const customSetValue = setValue as any
          customSetValue(key, value)
          storeFormData.current[key as keyof IQuiz] = value
        })
        storeFormData.current['constructed_questions'] = oldData.constructed_questions
        storeFormData.current['multiple_choice_questions'] = oldData.multiple_choice_questions
        setCanEdit(!(oldData?.state as QuizState)?.condition?.can_edit_quiz?.other)
      }
    }, [oldData])

    useEffect(() => {
      if (isSubmitted) {
        trigger('number_of_multiple_choice_questions')
        // trigger('emptyMultiple')
      }
    }, [questionsMultiple])

    useEffect(() => {
      if (isSubmitted) {
        trigger('number_of_essay_questions')
        trigger('emptyConstructed')
      }
    }, [questionsConstructed])
    /**
     * @description Chuyển đổi data lấy từ API thành form data
     * @param {*} data
     * @return {*}  {typeof defaultValues}
     */
    const convertResultToFormValues = (data: any): IQuiz => {
      setQuestionsMultiple(data.multiple_choice_questions || [])
      setQuestionsConstructed(data.constructed_questions || [])
      return {
        name: data.name,
        status: data.status,
        type: data.quiz_question_type,
        testMode: data.is_tutor ? TEST_MODE.tutor.value : TEST_MODE.timed.value,
        gradedAssignment: data.is_graded ? GRADED_ASSIGNMENT.yes.value : GRADED_ASSIGNMENT.no.value,
        time: {
          hours: Math.floor(data.quiz_timed / 60)?.toString(),
          mins: (data.quiz_timed % 60)?.toString(),
        },
        gradingPreference:
          data.grading_preference === 'AFTER_EACH_QUESTION'
            ? GRADING_PREFERENCE.each.value
            : GRADING_PREFERENCE.all.value,
        passPoint: {
          type: data.is_auto_pass_point ? PASS_POINT.auto.value : PASS_POINT.manual.value,
          value: data.is_auto_pass_point ? '' : data.required_percent_score?.toString(),
        },
        attempt: {
          type: data.is_limited ? ATTEMPT.limited.value : ATTEMPT.unlimited.value,
          value: data.is_limited ? data.limit_count?.toString() : '',
        },
        number_of_multiple_choice_questions: data.number_of_multiple_choice_questions?.toString(),
        number_of_essay_questions: data.number_of_essay_questions?.toString(),
        quiz_type: data.quiz_type,
        manualGrading: data?.grading_method,
      }
    }

    /**
     * @description Trở về màn course
     */
    const handleCancel = () => {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
        onClick: () => navigate(PageLink.COURSE_FULL + '/' + params.courseId + '?page=1'),
      })
    }

    /**
     * @description Mở Drawer list question
     * @param {LIST_TYPE} type
     */
    const handleChooseQuestions = (type: LIST_TYPE) => {
      if (getValues('type') === TYPE_OF_TEST.mix.value) {
        if (type === 'multiple' && !Number(getValues('number_of_multiple_choice_questions'))) {
          setError('number_of_multiple_choice_questions', { message: VALIDATION_FIELD })
          addQuestioned.current = { ...addQuestioned.current, multiple: true }
          return
        }
        if (type === 'constructed' && !Number(getValues('number_of_essay_questions'))) {
          setError('number_of_essay_questions', { message: VALIDATION_FIELD })
          addQuestioned.current = { ...addQuestioned.current, constructed: true }
          return
        }
      }
      setOpenChooseQuestion(type)
    }

    /**
     * @description Lấy data message lỗi 100% của percent
     * @param {*} type
     */
    const messageErrorTotalPercent = (type: string) => {
      setError(type, {
        type: 'custom',
        message: 'Mark of all questions not equal 100%',
      })
    }

    /**
     * @description Lấy data message lỗi min 0 của percent
     * @param {*} type
     */
    const messageErrorMinPercent = (type: string, total: number) => {
      setError(type, {
        type: 'custom',
        message: `Mark of all questions not equal ${total || 0}`,
      })
    }

    /**
     * @description Lấy tổng % của item child
     * @param {*} object
     * @return {*}  {number}
     */
    const getTotalChildrentPercent = (param: any) => {
      let totalNumber = 0
      param?.map((e: any) => {
        const getPercent = !isUndefined(watch(`question_percent_${e.id}`))
          ? watch(`question_percent_${e.id}`)
          : 0 || e?.setting_grade
        totalNumber = totalNumber + Number(getPercent)
      })
      return round(totalNumber, 2)
    }

    /**
     * @description Kiểm tra xem có phần tử nào có % của item child không lớn hơn 0 hay không
     * @param {Array} param - Mảng chứa các phần tử cần kiểm tra
     * @return {boolean} - Kết quả true/false
     */
    const hasChildPercentLessThanOrEqualToZero = (param: any[]): boolean => {
      return param?.some((e: any) => {
        return Number(getValues(`question_percent_${e.id}`)) <= 0
      })
    }

    /**
     * @description Tạo object của question
     * @param {*} any, array
     * @return {*} array
     */
    const settingObjectQuizQuestions = (questions: any, data: any) => {
      questions.forEach((question: any) => {
        data.push({
          question_id: question?.id || '',
          setting_grade: getValues(`question_percent_${question.id}`)
            ? Number(getValues(`question_percent_${question.id}`))
            : Number(question?.setting_grade),
        })
      })
      return data
    }

    /**
     * @description handle Validate data after submitting
     * @param {IQuiz} data
     */
    const isValidation = (data: IQuiz): boolean => {
      if (
        (Number(data?.number_of_essay_questions) === 0 ||
          Number(data?.number_of_multiple_choice_questions) === 0) &&
        (questionsConstructed.length > 0 || questionsMultiple.length > 0)
      ) {
        setError('number_of_multiple_choice_questions', {
          message: 'Number of added questions does not equal to the setting value',
        })
        setError('number_of_essay_questions', {
          message: 'Number of added questions does not equal to the setting value',
        })

        setError('question_error', {
          message: 'Number of added questions does not equal to the setting value',
        })
        return true
      }
      if (Number(data?.number_of_essay_questions) === 0) {
        setError('number_of_essay_questions', { message: VALIDATION_FIELD })
        return true
      }
      if (Number(data?.number_of_multiple_choice_questions) === 0) {
        setError('number_of_multiple_choice_questions', { message: VALIDATION_FIELD })
        return true
      }

      if (!data?.number_of_essay_questions && percentQuestionsMulti?.contruct) {
        setError('number_of_essay_questions', { message: VALIDATION_FIELD })
        return true
      }

      if (!data?.number_of_multiple_choice_questions && percentQuestionsMulti?.multi) {
        setError('number_of_multiple_choice_questions', { message: VALIDATION_FIELD })
        return true
      }
      if (
        (!percentQuestionsMulti?.multi ||
          !percentQuestionsMulti?.contruct ||
          percentQuestionsMulti?.multi + percentQuestionsMulti?.contruct !== 100) &&
        (isNull(testDetail?.multiple_choice_multiplier) || isNull(testDetail?.essay_multiplier))
      ) {
        setError('get_percent_questions_constructed', { message: 'Total point must be 100%' })
        setError('get_percent_questions_multi', { message: 'Total point must be 100%' })
        return true
      }
      const isFillQuestion =
        questionsMultiple.length !== Number(data?.number_of_multiple_choice_questions) ||
        questionsConstructed.length !== Number(data.number_of_essay_questions)

      if (isFillQuestion) {
        if (questionsMultiple?.length !== Number(data?.number_of_multiple_choice_questions)) {
          setError('number_of_multiple_choice_questions', {
            message: 'Number of added questions does not equal to the setting value',
          })
          setError('question_error', {
            message: 'Number of added questions does not equal to the setting value',
          })
        }
        if (questionsConstructed.length !== Number(data?.number_of_essay_questions)) {
          setError('number_of_essay_questions', {
            message: 'Number of added questions does not equal to the setting value',
          })
          setError('question_error', {
            message: 'Number of added questions does not equal to the setting value',
          })
        }

        return true
      }

      return false
    }

    /**
     * @description Lấy data từ form để create và edit quiz
     * @param {IQuiz} data
     */
    const onSubmit = handleSubmit(async (data: IQuiz) => {
      clearErrors('question_error')
      if (
        data.type === TYPE_OF_TEST.mix.value &&
        (Number(data.number_of_multiple_choice_questions) > 0 ||
          Number(data.number_of_essay_questions) > 0 ||
          questionsMultiple.length > 0 ||
          questionsConstructed.length > 0) &&
        isValidation(data)
      ) {
        return
      }

      // const isNotMultipleChoice = data.type !== 'MULTIPLE_CHOICE'
      // const isGradedAssignment = data.gradedAssignment === 'YES'
      // const isUnlimitedAttempt = data.attempt.type === 'UNLIMITED'
      // const isLimitedAttemptWithMultipleTries =
      //   data.attempt.type === 'LIMITED' && data.attempt.value !== '1'

      // const result =
      //   isNotMultipleChoice &&
      //   isGradedAssignment &&
      //   (isUnlimitedAttempt || isLimitedAttemptWithMultipleTries)

      // if (result) {
      //   setError('error_attempt', {message: 'You can only fill in a value of 1', type: 'manual'})
      //   return
      // }

      setLoading(true)
      try {
        // Check validate percent
        let flagPercent = false
        const getTotalMulti = Number(getValues(`get_percent_questions_multi`))
        const getTotalConstructed = Number(getValues(`get_percent_questions_constructed`))
        const getTotalChildMulti = round(getTotalChildrentPercent(questionsMultiple), 4)
        const getTotalChildConstructed = round(getTotalChildrentPercent(questionsConstructed), 4)
        const isZeroChildConstructed = hasChildPercentLessThanOrEqualToZero(questionsConstructed)
        const isConstructed = getTotalConstructed === getTotalChildConstructed
        const isTotalPercent = Math.round(getTotalMulti + getTotalConstructed) === 100
        const grading_method =
          getValues('type') === 'MULTIPLE_CHOICE'
            ? 'AUTO'
            : data.manualGrading === 'MANUAL'
            ? 'MANUAL'
            : 'AUTO'

        const isTotalEqual = (a: number, b: number) => a === b
        const isTotalInRange = (total: number) => total === 0 || total === 100

        const isPercentValid = () => {
          const isMultiEqual = isTotalEqual(getTotalMulti, getTotalChildMulti)
          const isConstructedEqual = isTotalEqual(getTotalConstructed, getTotalChildConstructed)
          const isSumInRange = isTotalInRange(sum([getTotalMulti, getTotalConstructed]))
          const isChildSumInRange = isTotalInRange(
            sum([getTotalChildConstructed, getTotalChildMulti])
          )

          return (isMultiEqual || isConstructedEqual) && isSumInRange && isChildSumInRange
        }

        if (watchType === TYPE_OF_TEST.multipleChoice.value) {
          getTotalChildMulti !== 0 && getTotalChildMulti !== 100
            ? messageErrorTotalPercent('get_percent_questions_multi')
            : (flagPercent = true)
        } else if (watchType === TYPE_OF_TEST.constructed.value) {
          if (getTotalChildConstructed !== 0 && getTotalChildConstructed !== 100) {
            messageErrorTotalPercent('get_percent_questions_constructed')
          } else {
            flagPercent = true
          }
        } else if (watchType === TYPE_OF_TEST.mix.value) {
          flagPercent = true
          if (
            Number(data.number_of_multiple_choice_questions) > 0 ||
            Number(data.number_of_essay_questions) > 0
          ) {
            if (!isPercentValid()) {
              if (
                getTotalChildrentPercent(questionsMultiple) !==
                getValues('get_percent_questions_multi')
              ) {
                flagPercent = false
                messageErrorMinPercent(
                  'get_percent_questions_multi',
                  getValues('get_percent_questions_multi')
                )
              }

              if (
                getTotalChildrentPercent(questionsConstructed) !==
                getValues('get_percent_questions_constructed')
              ) {
                flagPercent = false
                messageErrorMinPercent(
                  'get_percent_questions_constructed',
                  getValues('get_percent_questions_constructed')
                )
              }
            }
            // !isConstructed && messageErrorTotalPercent('get_percent_questions_constructed')
            // getTotalMulti === 0 && messageErrorMinPercent('get_percent_questions_multi')
            // getTotalConstructed === 0 ||
            //   (isZeroChildConstructed && messageErrorMinPercent('get_percent_questions_constructed'))

            // if (!isTotalPercent) {
            //   messageErrorTotalPercent('get_percent_questions_multi')
            //   messageErrorTotalPercent('get_percent_questions_constructed')
            // }

            if (
              isConstructed &&
              isTotalPercent &&
              getTotalMulti !== 0 &&
              getTotalConstructed !== 0 &&
              !isZeroChildConstructed &&
              sum([getTotalChildConstructed, getTotalChildMulti]) !== 0 &&
              sum([getTotalChildConstructed, getTotalChildMulti]) !== 100
            ) {
              flagPercent = false
            }
          }
        }
        if (customOnSubmit && forTab) {
          const payload = {
            quiz_question_mode: 'ALL',
            is_published: true,
            is_public: true,
            is_graded: data.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,

            grading_method: grading_method,
            grading_preference: data.gradingPreference,
            required_percent_score:
              data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),

            is_limited: data.attempt.type === ATTEMPT.limited.value ? true : false,
            ...(data.attempt.type === ATTEMPT.limited.value && {
              limit_count: Number(data.attempt.value) || 0,
            }),
            is_tutor: data.testMode === TEST_MODE.tutor.value ? true : false,
            ...(data.testMode === TEST_MODE.timed.value && {
              quiz_timed: Number(data.time.hours) * 60 + Number(data.time.mins),
            }),
            quiz_question_type: data.type,
            quiz_type: type?.toUpperCase(),
            quiz_questions: (() => {
              const dataQuestions: Array<any> = []
              if (data.type === TYPE_OF_TEST.mix.value) {
                settingObjectQuizQuestions(questionsConstructed, dataQuestions)
                settingObjectQuizQuestions(questionsMultiple, dataQuestions)
                return dataQuestions
              }
              if (data.type === TYPE_OF_TEST.constructed.value) {
                settingObjectQuizQuestions(questionsConstructed, dataQuestions)
                return dataQuestions
              }
              if (data.type === TYPE_OF_TEST.multipleChoice.value) {
                settingObjectQuizQuestions(questionsMultiple, dataQuestions)
                return dataQuestions
              }
              return []
            })(),
            course_id: params?.courseId,
            multiple_choice_multiplier: getTotalMulti,
            essay_multiplier: getTotalConstructed,
          }
          if (flagPercent) {
            await customOnSubmit(payload)
          }
        } else {
          const request = {
            name: data.name.trim(),
            quiz_question_mode: 'ALL',
            status: data.status,
            is_published: true,
            is_public: true,

            number_of_multiple_choice_questions:
              Number(data.number_of_multiple_choice_questions) || 0,
            number_of_essay_questions: Number(data.number_of_essay_questions) | 0,

            is_graded: data.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,
            grading_method: grading_method,
            grading_preference: data.gradingPreference,
            required_percent_score:
              data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),

            is_limited: data.attempt.type === ATTEMPT.limited.value ? true : false,
            ...(data.attempt.type === ATTEMPT.limited.value && {
              limit_count: Number(data.attempt.value) || 0,
            }),

            is_tutor: data.testMode === TEST_MODE.tutor.value ? true : false,
            ...(data.testMode === TEST_MODE.timed.value && {
              quiz_timed: Number(data.time.hours) * 60 + Number(data.time.mins),
            }),

            quiz_question_type: data.type,
            quiz_type: type?.toUpperCase(),

            ...(Object.keys(TEST_TYPE)
              .filter(
                (e) =>
                  e !== TEST_TYPE.MID_TERM_TEST &&
                  e !== TEST_TYPE.FINAL_TEST &&
                  e !== TEST_TYPE.MOCK_TEST
              )
              .includes(getValues('quiz_type')?.toLocaleUpperCase() || '')
              ? {
                  course_section_id: !editId ? id : undefined,
                }
              : {
                  course_id: !editId ? id : undefined,
                }),
            quiz_questions: (() => {
              const dataQuestions: Array<any> = []
              if (data.type === TYPE_OF_TEST.mix.value) {
                settingObjectQuizQuestions(questionsConstructed, dataQuestions)
                settingObjectQuizQuestions(questionsMultiple, dataQuestions)
                return dataQuestions
              }
              if (data.type === TYPE_OF_TEST.constructed.value) {
                settingObjectQuizQuestions(questionsConstructed, dataQuestions)
                return dataQuestions
              }
              if (data.type === TYPE_OF_TEST.multipleChoice.value) {
                settingObjectQuizQuestions(questionsMultiple, dataQuestions)
                return dataQuestions
              }
              return []
            })(),
            quiz_for: !editId ? testFor.toUpperCase() : undefined,
            course_id: params?.courseId,
            multiple_choice_multiplier: getTotalMulti,
            essay_multiplier: getTotalConstructed,
            is_auto_pass_point: data?.passPoint?.type === 'AUTO' ? true : false,
          }

          let response: any = []

          if (flagPercent) {
            if (editId) {
              response = await CoursesAPI.updateQuiz(editId, request)
              setIdChild(response?.data?.course_section?.id)
            } else {
              response = await CoursesAPI.createQuiz(request)
              setIdChild(response?.data?.course_section_id)
            }

            if (response.success) {
              toast.success('Quiz saved successfully!')
              setQuestionsMultiple([])
              setQuestionsConstructed([])
              reset(defaultValues)
              navigate(PageLink.COURSE_FULL + '/' + params.courseId + '?page=1')
            }
          }
        }
      } finally {
        setLoading(false)
      }
    })

    const onCancel = () => {
      !customOnCancel ? handleCancel() : customOnCancel()
    }

    useImperativeHandle(ref, () => {
      return {
        onSubmit,
        onCancel,
      }
    })

    /**
     * @description Chuyển dữ liệu của form trừ name và default khi chuyển type
     * Nếu là edit và type đã chọn bằng type lấy về từ api thì hiện hiện thị dữ liệu vừa lấy
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onTypeOfTestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValues: IQuiz
      if (editId && storeFormData.current && e.target.value === storeFormData.current.type) {
        newValues = storeFormData.current
        setQuestionsConstructed(storeFormData.current.constructed_questions || [])
        setQuestionsMultiple(storeFormData.current.multiple_choice_questions || [])
      } else {
        newValues = { ...defaultValues }
        setQuestionsConstructed([])
        setQuestionsMultiple([])
      }
      setValue('testMode', newValues.testMode)
      setValue('gradedAssignment', newValues.gradedAssignment)
      setValue('time', newValues.time)
      setValue('gradingPreference', newValues.gradingPreference)
      setValue('passPoint', newValues.passPoint)
      setValue('attempt', newValues.attempt)
      setValue('number_of_multiple_choice_questions', newValues.number_of_multiple_choice_questions)
      setValue('number_of_essay_questions', newValues.number_of_essay_questions)
      setValue('manualGrading', newValues.manualGrading)
      clearErrors()
    }

    const { search } = useLocation()
    const quizType = search?.split('=')?.[1]

    const getTitle = (): string => {
      if (quizType === TEST_TYPE.MID_TERM_TEST) {
        return 'MidTerm Test'
      }
      if (quizType === TEST_TYPE.FINAL_TEST) {
        return 'Final Test'
      }
      if (quizType === TEST_TYPE.TOPIC_TEST) {
        return 'Part/ Topic Test'
      }
      if (quizType === TEST_TYPE.CHAPTER_TEST) {
        return 'Chapter/ Module Test'
      }
      if (quizType === TEST_TYPE.MOCK_TEST) {
        return 'Mock Test'
      }
      return ''
    }

    const [percentQuestionsMulti, setPercentQuestionsMulti] = useState<{
      multi: number
      contruct: number
    }>({ multi: 0, contruct: 0 })

    useEffect(() => {
      if (watch('manualGrading') === 'MANUAL') {
        setValue('attempt.type', 'LIMITED')
        setValue('attempt.value', '1')
      }
    }, [watch('manualGrading'), watch('type')])

    const enableAttempt = watch('manualGrading') === 'MANUAL' && watch('type') !== 'MULTIPLE_CHOICE'

    return (
      <div className='sapp-modal--content'>
        {contextHolder}
        <div>
          <div className='d-flex justify-content-between align-items-start sapp-px-24px'>
            <div className='d-flex gap-2 justify-content-center align-items-center'></div>
          </div>
          <div className=''>
            {!forTab && (
              <React.Fragment>
                <SappLabel required label={`${getTitle()} Name`}></SappLabel>
                {/* start:: name*/}
                <div className='mb-10'>
                  <HookFormTextField
                    control={control}
                    name='name'
                    placeholder=''
                    disabled={false}
                    skeleton={skeleton}
                  ></HookFormTextField>
                </div>
              </React.Fragment>
            )}
            {/* end:: name*/}
            {/* start:: Type of Test*/}
            <div className='mb-10'>
              <SappLabel required label={`Type of Test`}></SappLabel>
              {!skeleton ? (
                <HookFormRadioGroup
                  labelClass='fw-semibold fs-6'
                  direction='horizontal'
                  separator={false}
                  name='type'
                  control={control}
                  justify='start'
                  // gap={20}
                  disabled={canEdit}
                  options={Object.values(TYPE_OF_TEST ?? {}).map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  onChange={onTypeOfTestChange}
                  itemWidth={'25%'}
                />
              ) : (
                <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
              )}
            </div>
            {watchType === TYPE_OF_TEST.mix.value && (
              <div className='mb-10'>
                <SappLabel required label={'Number of Questions'}></SappLabel>
                <Row className='align-items-start'>
                  <Col>
                    <HookFormTextField
                      control={control}
                      name='number_of_multiple_choice_questions'
                      placeholder='Multiple Choice'
                      type='number'
                      disabled={canEdit}
                      skeleton={false}
                    ></HookFormTextField>
                  </Col>
                  <Col>
                    <HookFormTextField
                      control={control}
                      name='number_of_essay_questions'
                      placeholder='Constructed'
                      type='number'
                      disabled={canEdit}
                      skeleton={skeleton}
                      onChange={(e) => {
                        if (!isSubmitted && addQuestioned.current.constructed) {
                          if (!Number(getValues('number_of_essay_questions'))) {
                            setError('number_of_essay_questions', { message: VALIDATION_FIELD })
                          } else {
                            clearErrors('number_of_essay_questions')
                          }
                        }
                      }}
                    ></HookFormTextField>
                  </Col>
                </Row>
              </div>
            )}
            {/* end:: Type of Test*/}
            {/* <Row className='mb-10 row-gap-15'> */}
            {/* start:: Test Mode*/}
            {/* <Col> */}
            <div className='mb-10'>
              <SappLabel required label={'Test Mode'}></SappLabel>
              {!skeleton ? (
                <HookFormRadioGroup
                  labelClass='fw-semibold fs-6'
                  direction='horizontal'
                  separator={false}
                  name='testMode'
                  control={control}
                  justify='start'
                  // gap={20}
                  disabled={canEdit}
                  options={Object.values(TEST_MODE ?? {}).map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  onChange={() => {
                    setValue('time.hours', '')
                    setValue('time.mins', '')
                    clearErrors('time.hours')
                    clearErrors('time.mins')
                  }}
                  itemWidth={'25%'}
                />
              ) : (
                <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
              )}
            </div>
            {/* </Col> */}
            {['ESSAY', 'MIX'].includes(watch('type')) && (
              <div className='mb-10'>
                <SappLabel required label={'Manual Grading'}></SappLabel>
                {!skeleton ? (
                  <HookFormRadioGroup
                    labelClass='fw-semibold fs-6'
                    direction='horizontal'
                    separator={false}
                    name='manualGrading'
                    control={control}
                    onChange={(data) => {
                      if (
                        data?.target?.value === 'MANUAL' &&
                        watch('quiz_type') !== TEST_TYPE.MOCK_TEST
                      ) {
                        setValue('gradedAssignment', 'YES')
                      }
                    }}
                    justify='start'
                    // gap={20}
                    disabled={canEdit}
                    options={Object.values(QUIZ_GRADING_METHOD ?? {}).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    itemWidth={'25%'}
                  />
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
            )}
            {/* start:: Duration*/}
            {watchTestMode === TEST_MODE.timed.value && (
              <div className='mb-10'>
                <SappLabel required label={'Duration'}></SappLabel>
                {!skeleton ? (
                  <Row className='align-items-start'>
                    <Col>
                      <HookFormTextField
                        control={control}
                        name='time.hours'
                        labelClass='d-flex align-items-center fs-6 fw-bold form-label required'
                        type='number'
                        disabled={canEdit}
                        onChange={() => {
                          if (isSubmitted) {
                            trigger('time.mins')
                          }
                        }}
                        skeleton={skeleton}
                        postFix={<div className='sapp-post-fix-text-field '>h</div>}
                      ></HookFormTextField>
                    </Col>
                    <Col>
                      <HookFormTextField
                        control={control}
                        name='time.mins'
                        type='number'
                        disabled={canEdit}
                        onChange={() => {
                          if (isSubmitted) {
                            trigger('time.hours')
                          }
                        }}
                        skeleton={skeleton}
                        postFix={<div className='sapp-post-fix-text-field '>m</div>}
                      ></HookFormTextField>
                    </Col>
                  </Row>
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
            )}
            {/* end:: Duration*/}
            {/* end:: Test Mode*/}
            {/* start:: Graded Assignment*/}
            {/* <Col> */}
            <div className='mb-10'>
              <SappLabel required label={'Graded Assignment'}></SappLabel>
              {!skeleton ? (
                <HookFormRadioGroup
                  labelClass='fw-semibold fs-6'
                  direction='horizontal'
                  separator={false}
                  name='gradedAssignment'
                  control={control}
                  justify='start'
                  // gap={20}
                  disabled={
                    quizType === TEST_TYPE.MOCK_TEST ||
                    canEdit ||
                    (watch('type') !== 'MULTIPLE_CHOICE' && watch('manualGrading') === 'MANUAL')
                  }
                  options={Object.values(GRADED_ASSIGNMENT ?? {}).map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  itemWidth={'25%'}
                />
              ) : (
                <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
              )}
            </div>
            {/* </Col> */}
            {/* end:: Graded Assignment*/}
            {/* </Row> */}
            {/* start:: Grading Preference*/}

            <div className='mb-10'>
              <SappLabel required label={'Grading Preference'}></SappLabel>
              {!skeleton ? (
                <div>
                  <HookFormRadioGroup
                    labelClass='fw-semibold fs-6'
                    direction='horizontal'
                    separator={false}
                    name='gradingPreference'
                    control={control}
                    justify='start'
                    disabled={canEdit}
                    options={Object.values(GRADING_PREFERENCE ?? {}).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    itemWidth={'25%'}
                  />
                </div>
              ) : (
                <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
              )}
            </div>
            {/* end:: Grading Preference*/}
            {/* start:: Pass Points*/}
            {watchGradeAssignment === 'YES' && (
              <div className='mb-6'>
                <SappLabel required label={'Pass Point'}></SappLabel>
                <div className='d-flex justify-content-between '>
                  <div className='mb- w-50'>
                    {!skeleton ? (
                      <div className=''>
                        <HookFormRadioGroup
                          labelClass='fw-semibold fs-6'
                          direction='horizontal'
                          separator={false}
                          name='passPoint.type'
                          control={control}
                          options={Object.values(PASS_POINT ?? {}).map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                          // gap={20}
                          justify='start'
                          disabled={canEdit}
                          itemWidth={'50%'}
                        />
                      </div>
                    ) : (
                      <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                    )}
                  </div>
                  {watchPassPoint?.type === PASS_POINT.manual.value && (
                    <div className='align-self-end sapp-w-49'>
                      <HookFormTextField
                        disabled={editOnlyName}
                        control={control}
                        name='passPoint.value'
                        placeholder=''
                        postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                        type='number'
                      ></HookFormTextField>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* end:: Pass Points*/}
            {/* start:: Attempt*/}
            <div className='sapp-mb-24px'>
              <div className='mb-1'>
                <SappLabel required label={'Attempt'}></SappLabel>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='w-50'>
                  {!skeleton ? (
                    <div className=''>
                      <HookFormRadioGroup
                        labelClass='fw-semibold fs-6'
                        direction='horizontal'
                        separator={false}
                        name='attempt.type'
                        control={control}
                        options={Object.values(ATTEMPT ?? {}).map((item) => ({
                          label: item.label,
                          value: item.value,
                        }))}
                        // gap={20}
                        justify='start'
                        disabled={canEdit || enableAttempt}
                        itemWidth={'50%'}
                      />
                    </div>
                  ) : (
                    <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                  )}
                </div>
                {watchAttempt?.type === ATTEMPT.limited.value && (
                  <div className='align-self-end sapp-w-49 '>
                    <HookFormTextField
                      control={control}
                      name='attempt.value'
                      placeholder=''
                      disabled={canEdit || enableAttempt}
                      // skeleton={loadingFetchData}
                      type='number'
                    ></HookFormTextField>
                  </div>
                )}
              </div>
              <ErrorMessage>{errors?.error_attempt?.message as string}</ErrorMessage>

              {watchType === TYPE_OF_TEST.mix.value && (
                <div className='sapp-mt-24px'>
                  {/* <SappLabel required label={'Number of Questions'}></SappLabel> */}
                  <Row className='align-items-start'>
                    <Col>
                      <HookFormTextField
                        control={control}
                        name={`get_percent_questions_multi`}
                        placeholder='100%'
                        type='number'
                        // className='w-200px sapp-input-percent-total fs-6 lh-1 sapp-table-class-field'
                        disabled={watchType !== TYPE_OF_TEST.mix.value ? true : false}
                        onChange={(e) => {
                          setValue('get_percent_questions_multi', e.target.value)
                          setPercentQuestionsMulti((prev: { multi: number; contruct: number }) => ({
                            ...prev,
                            multi: Number(e.target.value),
                          }))
                        }}
                        postFix={<div className='px-2'>%</div>}
                        label='Trọng số trắc nghiệm'
                        required
                      />
                    </Col>
                    <Col>
                      <HookFormTextField
                        control={control}
                        name={`get_percent_questions_constructed`}
                        type='number'
                        placeholder='100%'
                        disabled={watchType !== TYPE_OF_TEST.mix.value ? true : false}
                        onChange={(e) => {
                          setValue('get_percent_questions_constructed', e.target.value)
                          setPercentQuestionsMulti((prev: { multi: number; contruct: number }) => ({
                            ...prev,
                            contruct: Number(e.target.value),
                          }))
                        }}
                        postFix={<div className='px-2'>%</div>}
                        label='Trọng số tự luận'
                        required
                      ></HookFormTextField>
                    </Col>
                  </Row>
                </div>
              )}
            </div>

            {(watchType === TYPE_OF_TEST.multipleChoice.value ||
              watchType === TYPE_OF_TEST.mix.value) && (
              <div className='mb-10'>
                <div className='d-flex'>
                  <SappLabel required label={'List Multiple Choice Questions'} />
                  <div className='text-gray-800 sapp-fs-14 fw-semibold sapp-mb-10px sapp-line--height ms-2'>
                    (
                    <span className='sapp-text-danger'>
                      {round(getTotalChildrentPercent(questionsMultiple), 2)}
                    </span>
                    )
                  </div>
                </div>
                <QuizQuestionTable
                  maxQuestionsNumber={
                    watchType === TYPE_OF_TEST.mix.value
                      ? Number(watchNumberOfMultipleChoiceQuestions) || 0
                      : 'NO_MAX'
                  }
                  error={
                    errors?.question_error?.message ||
                    errors['emptyMultiple'] ||
                    (errors?.get_percent_questions_multi?.message as any)
                  }
                  questions={questionsMultiple}
                  setQuestions={setQuestionsMultiple}
                  handleChooseQuestions={() => handleChooseQuestions('multiple')}
                  confirm={confirm}
                  disabled={canEdit}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  watchType={watchType}
                  defaultType={'multi'}
                  totalQuestionsMultiple={totalQuestionsMultiple}
                  totalQuestionsConstructed={totalQuestionsConstructed}
                  percentQuestionsMulti={percentQuestionsMulti.multi}
                  setPercentQuestionsMulti={setPercentQuestionsMulti}
                  errors={
                    getTotalChildrentPercent(questionsMultiple) !==
                      getValues('get_percent_questions_multi') || !!errors?.question_error?.message
                  }
                ></QuizQuestionTable>
              </div>
            )}
            {(watchType === TYPE_OF_TEST.constructed.value ||
              watchType === TYPE_OF_TEST.mix.value) && (
              <div className='mb-10'>
                <div className='d-flex'>
                  <SappLabel required label={'List Constructed Questions'} />
                  <div className='text-gray-800 sapp-fs-14 fw-semibold sapp-mb-10px sapp-line--height ms-2'>
                    (
                    <span className='sapp-text-danger'>
                      {round(getTotalChildrentPercent(questionsConstructed), 2)}
                    </span>
                    )
                  </div>
                </div>
                <QuizQuestionTable
                  maxQuestionsNumber={
                    watchType === TYPE_OF_TEST.mix.value
                      ? Number(watchNumberOfEssayQuestions) || 0
                      : 'NO_MAX'
                  }
                  error={
                    (errors?.question_error?.message as any) ||
                    (errors['emptyConstructed'] as any) ||
                    (errors?.get_percent_questions_constructed?.message as string)
                  }
                  questions={questionsConstructed}
                  setQuestions={setQuestionsConstructed}
                  handleChooseQuestions={() => handleChooseQuestions('constructed')}
                  confirm={confirm}
                  disabled={canEdit}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  watchType={watchType}
                  defaultType={'constructed'}
                  totalQuestionsMultiple={totalQuestionsMultiple}
                  totalQuestionsConstructed={totalQuestionsConstructed}
                  percentQuestionsMulti={percentQuestionsMulti.contruct}
                  setPercentQuestionsMulti={() => {}}
                  errors={
                    getTotalChildrentPercent(questionsConstructed) !==
                      getValues('get_percent_questions_constructed') ||
                    !!errors?.question_error?.message
                  }
                ></QuizQuestionTable>
              </div>
            )}
            {isCreateEditLayout && (
              <div className='card-footer'>
                <SAPPDialogButtonsCancelSubmit
                  cancelClick={!customOnCancel ? handleCancel : customOnCancel}
                  cancelButtonCaption='Cancel'
                  okButtonCaption={buttonSaveTitle}
                  okOnClick={handleSubmit(onSubmit)}
                  className={CancelSubmitBtnClass}
                  loading={loading}
                  disabled={false}
                />
              </div>
            )}
          </div>

          <ChooseQuestionsModal
            questions={openChooseQuestion === 'multiple' ? questionsMultiple : questionsConstructed}
            maxQuestionsNumber={
              watchType === TYPE_OF_TEST.mix.value
                ? openChooseQuestion === 'multiple'
                  ? Number(watchNumberOfMultipleChoiceQuestions) || 0
                  : Number(watchNumberOfEssayQuestions) || 0
                : 'NO_MAX'
            }
            setQuestions={
              openChooseQuestion === 'multiple' ? setQuestionsMultiple : setQuestionsConstructed
            }
            open={openChooseQuestion}
            setOpen={setOpenChooseQuestion}
            type={openChooseQuestion as LIST_TYPE | boolean}
            editing={oldData?.id || editId}
            isTest={[
              TEST_TYPE.CHAPTER_TEST,
              TEST_TYPE.TOPIC_TEST,
              TEST_TYPE.FINAL_TEST,
              TEST_TYPE.MID_TERM_TEST,
              TEST_TYPE.QUIZ,
            ].includes(watchQuizType)}
          ></ChooseQuestionsModal>
        </div>
      </div>
    )
  }
)

export default TestComponent
