import { Link } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import clsx from 'clsx'

interface IProps {
  onClick?: (e?: any) => void
  type?: 'button' | 'submit' | 'reset' | undefined
  link?: string | undefined
  fs?: string
  activeColor?:
    | 'primary'
    | 'danger'
    | 'success'
    | 'info'
    | 'warning'
    | 'secondary'
    | 'dark'
    | 'light'
  iconName: string
  iconType?: 'duotone' | 'solid' | 'outline'
  active?: boolean
  bg?: string
  disabled?: boolean
  iconGrayColor?: string
  disableBackgoundHover?: boolean
  className?: string
  applyActiveColorButton?: boolean
}

const ButtonIconOnly = ({
  onClick,
  type = 'button',
  link,
  fs = '1',
  activeColor = 'primary',
  iconName,
  iconType = 'outline',
  active,
  bg = 'white',
  disabled,
  iconGrayColor = '#99A1B7',
  className,
  applyActiveColorButton = true,
}: IProps) => {
  const iconClass = clsx(`fs-${fs}`, applyActiveColorButton && `button-${activeColor}`)

  if (link) {
    return (
      <Link
        to={link}
        className={`${
          active ? 'active' : ''
        } btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-${activeColor} h-30px w-lg-30px h-lg-30px bg-${bg}`}
        onClick={onClick}
      >
        <KTIcon iconType={iconType} iconName={iconName} />
      </Link>
    )
  }
  return (
    <button
      disabled={disabled}
      className={`${
        active ? 'active' : ''
      } btn btn-icon btn-icon-gray-${iconGrayColor} btn-active-light btn-active-color-${activeColor} h-30px w-lg-30px h-lg-30px bg-${bg} ${
        className ?? ''
      }`}
      onClick={onClick}
      type={type}
    >
      <KTIcon iconType={iconType} iconName={iconName} />
    </button>
  )
}

export default ButtonIconOnly
