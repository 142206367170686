import { IMeta } from '.'
import { ITeacherClass } from './teacher'

export interface IClass {
  id?: string
  name: string
  code: string
  status: string
  facility_id: string | null | undefined
  instruction_mode: string
  type: string
  capacity: number
  duration_type: string
  course_id: string | null
  setting?: object
  excepted_course_section?: any
  acca_examination_id?: string
  cfa_examination_id?: string
  cma_examination_id?: string
  opening_at?: Date
  started_at?: Date
  finished_at?: Date
  duration_at?: any
  flexible_days?: number
  description?: string
  users?: any
  reason?: any
  import_users?: FormData
  lectures?: ITeacherClass[]
  class_lectures?: ITeacherClass[]
}

export interface IClassDetail {
  key: string
  id: string
  acca_examination: any
  cfa_examination: any
  cma_examination: any
  area: object
  capacity: number
  code: string
  course: object
  name: string
  duration_type: string
  created_at: string
  instruction_mode: string
  lecture: object
  mentor_id: string
  mentor_type: string
  status: string
  type: string
  opening_at?: string
  started_at?: string
  finished_at?: string
  updated_at?: string
  deleted_at?: string
  description?: string
  excepted_course_section?: any
  flexible_days?: number
}

type LearningProgress = {
  total_course_sections: number
  total_course_sections_completed: number
}

export interface IClassStudent {
  class_id: string
  class_user_score: null
  created_at: Date
  deleted_at: null
  excepted_course_section: string | null
  finished_at: Date | null
  id: string
  is_passed: boolean
  started_at: Date
  status: string
  updated_at: Date
  user_id: string
  user: any
  learning_progress: LearningProgress
  is_sapp_operator: boolean
}

export interface IClassQuizes {
  created_at: Date | null
  due_date_grade: Date
  end_time: Date
  start_time?: Date
  id: string
  mode: string
  quiz: {
    grading_method: 'AUTO' | 'MANUAL'
    id: string
    name: string
    quiz_type: string
    staff: {
      detail: {
        full_name: string
      }
      id: string
    }
    start_time: Date | null
  }
  total_attempts: number
  total_grading_attempts: number
}

export interface IClassQuizInstance {
  duration_type?: string
  finished_at?: string
  id?: string
  started_at?: string
}

export interface IClassQuiz {
  metadata: IMeta
  class_instance?: IClassQuizInstance
  class_quizzes: IClassQuizes[]
}

export enum QUIZ_TYPE {
  QUIZ = 'QUIZ',
  MID_TERM_TEST = 'MID_TERM_TEST',
  FINAL_TEST = 'FINAL_TEST',
  MOCK_TEST = 'MOCK_TEST',
  // ENTRANCE_TEST = 'ENTRANCE_TEST',
  STORY = 'STORY',
  TOPIC_TEST = 'TOPIC_TEST',
  CHAPTER_TEST = 'CHAPTER_TEST',
}
