import axios, { CancelTokenSource } from 'axios'
import { fetchFormPutData, fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IPreUploadVideo } from 'src/type/shop/common'
import {
  IProduct,
  IProductCategory,
  IProductCategoryCreate,
  IProductCategoryList,
  IProductForm,
  IProductItem,
  IProductList,
} from 'src/type/shop/product'

export class ProductAPI {
  static getProducttCategories({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IProductCategoryList>> {
    return fetcher(`/shop-products/categories?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getProdcutCategory(id: string): Promise<IResponse<IProductCategory>> {
    return fetcher(`/shop-products/categories/${id}`)
  }

  static createProdcutCategory({
    data,
  }: {
    data: IProductCategoryCreate
  }): Promise<IResponse<IProductCategory>> {
    return fetcher('/shop-products/categories', { method: 'POST', data: data })
  }

  static updateProdcutCategory({
    id,
    data,
  }: {
    id: string
    data: IProductCategoryCreate
  }): Promise<IResponse<IProductCategory>> {
    return fetcher(`/shop-products/categories/${id}`, { method: 'PUT', data: data })
  }

  static deleteProductCategory(id: string): Promise<IResponse<IProductCategory>> {
    return fetcher(`/shop-products/categories/${id}`, { method: 'DELETE' })
  }

  static deleteBulkProductCategory(
    shopProductCategoryIds: string[]
  ): Promise<IResponse<IProductCategory>> {
    return fetcher('/shop-products/categories/bulk-delete', {
      method: 'POST',
      data: { shopProductCategoryIds },
    })
  }

  // Products
  static getProducts({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IProductList>> {
    return fetcher(`/shop-products?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static deleteProduct(id: string): Promise<IResponse<IProductCategory>> {
    return fetcher(`/shop-products/${id}`, { method: 'DELETE' })
  }

  static duplicateProduct(id: string): Promise<IResponse<IProductItem>> {
    return fetcher(`/shop-products/duplicate`, { method: 'POST', data: { id } })
  }

  static deleteBulkProduct(shop_product_ids: string[]): Promise<IResponse<IProductCategory>> {
    return fetcher('/shop-products/bulk-delete', {
      method: 'POST',
      data: { shop_product_ids },
    })
  }

  static getProduct(id: string): Promise<IResponse<IProduct>> {
    return fetcher(`/shop-products/${id}`)
  }

  static createProdcut({ data }: { data: IProductForm }): Promise<IResponse<IProduct>> {
    return fetcher('/shop-products', { method: 'POST', data: data })
  }

  static updateProduct({
    id,
    data,
  }: {
    id: string
    data: IProductForm
  }): Promise<IResponse<IProduct>> {
    return fetcher(`/shop-products/${id}`, { method: 'PUT', data: data })
  }

  static updateProductStatus(id: string, status: string): Promise<IResponse<IProduct>> {
    return fetcher(`/shop-products/${id}/status`, { method: 'PUT', data: { status } })
  }

  static getClass({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/classes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
  static getCourseCategory({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<any> {
    return fetcher(`/course_categories?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getAreaClasses({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/facilities?page_index=${page_index}&page_size=${page_size}`, { params })
  }

  static getClassCodes(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<any>> {
    return fetcher(`/classes/codes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static uploadThumnail(data: { id: string; thumbnail: File }) {
    const formData = new FormData()
    formData.append('thumbnail_url', data.thumbnail)
    return fetchFormPutData({ url: `/shop-products/${data.id}/thumbnail`, formData })
  }

  static addResource(
    id: string,
    data: {
      name?: string
      file_key?: string
      location?: string
      description?: string
      size?: number
      parent_id?: null
    }
  ): Promise<IResponse<IProduct>> {
    return fetcher(`/shop-products/${id}/video-trailer`, { method: 'PUT', data })
  }

  static preUploadVideo(params: IPreUploadVideo): Promise<
    IResponse<{
      exist_type: string
      file_key: string
      type: string
      upload_url: string
    }>
  > {
    return fetcher(`/shop-products/pre-upload/metadata`, { params })
  }
  static uploadVideo = async (
    file: {
      contentType: string
      file_key?: string
      upload_url?: string
      blob?: Blob
      type?: string
    },
    source?: CancelTokenSource
  ) => {
    const fileBlob = file.blob
    try {
      if (!file?.upload_url) return
      await axios.put(file?.upload_url, fileBlob, {
        headers: { 'Content-Type': fileBlob?.type as string },
        ...(source && { cancelToken: source.token }),
      })
    } catch (error) {
      throw error
    }
  }
}
