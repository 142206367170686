import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CommonAPI } from 'src/apis'
import ClassroomApi from 'src/apis/classroom'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { PageLink, VALIDATION_EMPTY_FIELD, VALIDATION_FIELD } from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IErrorFormArea } from 'src/type'
import { ICommonAddress, IDistrictList, IProvinceList, IWardList } from 'src/type/common'
import { z } from 'zod'

interface IForm {
  name: string
  code: string
  province_code: string
  district_code: string
  ward_code: string
  address: string
  status: string
}

const NewArea = () => {
  const { Option } = Select
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [provinceList, setProvinceList] = useState<IProvinceList>()
  const [districtList, setDistrictList] = useState<IDistrictList | null>()
  const [wardList, setWardList] = useState<IWardList | null>()

  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    province_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    district_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    ward_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    address: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
  })

  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      province_code: '',
      district_code: '',
      ward_code: '',
      address: '',
    },
  })

  const onSubmit = async (data: IForm) => {
    setLoading(true)
    try {
      await ClassroomApi.createAreaClass(data)
      navigate(PageLink.CLASSROOM_AREA)
      toast.success('Create successfully!')
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorFormArea) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setLoading(false)
    }
  }

  const fetchProvince = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      const res = await CommonAPI.getProvince(pageIndex, pageSize, params)
      setProvinceList((prev) => {
        return {
          metadata: res.data.metadata,
          provinces: ((prev?.provinces || [])?.concat(res.data.provinces) ?? [])?.filter(
            (item, index, self) => index === self.findIndex((t) => t.code === item.code)
          ),
        }
      })
    } catch (error) {}
  }

  const fetchDistrict = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      const res = await CommonAPI.getDistrict(pageIndex, pageSize, params)
      setDistrictList((prev) => {
        return {
          metadata: res.data.metadata,
          districts:
            res.data.metadata.page_index === 1
              ? res.data.districts
              : ((prev?.districts || [])?.concat(res.data.districts) ?? [])?.filter(
                  (item, index, self) => index === self.findIndex((t) => t.code === item.code)
                ),
        }
      })
    } catch (error) {}
  }

  const fetchWards = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      const res = await CommonAPI.getWards(pageIndex, pageSize, params)
      setWardList((prev) => {
        return {
          metadata: res.data.metadata,
          wards:
            res.data.metadata.page_index === 1
              ? res.data.wards
              : ((prev?.wards || [])?.concat(res.data.wards) ?? [])?.filter(
                  (item, index, self) => index === self.findIndex((t) => t.code === item.code)
                ),
        }
      })
    } catch (error) {}
  }

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageSize && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.CLASSROOM_AREA),
    })
  }

  useEffect(() => {
    fetchProvince(1, 70)
  }, [])

  return (
    <CreateEditLayout
      customPrimaryButton={
        <ButtonPrimary
          onClick={handleSubmit(onSubmit)}
          type='submit'
          title='Save'
          loading={loading}
          size='small'
        />
      }
      onCancel={() => handleCancel()}
      loading={loading}
      pageTitle={TITLE_OPTIONS_AREA.createFacility}
    >
      {contextHolder}
      <div className='px-10 pt-8 pb-4 row'>
        <div className='mb-8 col-12'>
          <HookFormTextField
            required
            control={control}
            name='name'
            placeholder='Name'
            label='Name'
          />
        </div>
        <div className='mb-8 col-12'>
          <HookFormTextField
            required
            control={control}
            name='code'
            placeholder='Code'
            label='Code'
          />
        </div>
        <div className='mb-8 col-12 row'>
          <div className='col-lg-4 col-12'>
            <HookFormSelectAntd
              control={control}
              name='province_code'
              placeholder='Province'
              label='Province'
              required
              showSearch
              onChange={(value) => {
                if (!value) {
                  setValue('province_code', '')
                  setDistrictList(null)
                } else {
                  fetchDistrict(1, 10, { province_code: value })
                }
                setValue('district_code', '')
                setValue('ward_code', '')
                setWardList(null)
              }}
              onSearch={(value: string) => {
                fetchProvince(1, 10, { search: `name=${value}` })
              }}
              handleNextPage={() => {
                handleNextPage(
                  provinceList?.metadata?.total_pages,
                  provinceList?.metadata?.page_index,
                  provinceList?.metadata?.page_size,
                  fetchProvince
                )
              }}
            >
              {provinceList?.provinces?.map((province: ICommonAddress) => (
                <Option key={province?.code} value={province.code}>
                  {province.name}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-lg-4 col-12'>
            <HookFormSelectAntd
              control={control}
              name='district_code'
              placeholder='District'
              showSearch
              label='District'
              required
              onChange={(value) => {
                if (value) {
                  fetchWards(1, 10, { district_code: value })
                }
                setValue('ward_code', '')
                setWardList(null)
              }}
              onSearch={(value: string) => {
                if (getValues('province_code') && value) {
                  fetchDistrict(1, 10, {
                    search: `name=${value}`,
                    province_code: getValues('province_code'),
                  })
                }
              }}
              handleNextPage={() => {
                handleNextPage(
                  districtList?.metadata?.total_pages,
                  districtList?.metadata?.page_index,
                  districtList?.metadata?.page_size,
                  fetchDistrict,
                  { province_code: getValues('province_code') }
                )
              }}
            >
              {districtList?.districts?.map((district: ICommonAddress) => (
                <Option key={district?.code} value={district?.code}>
                  {district?.name_with_type}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-lg-4 col-12'>
            <HookFormSelectAntd
              control={control}
              name='ward_code'
              placeholder='Ward'
              showSearch
              label='Ward'
              onSearch={(value: string) => {
                if (!!value && getValues('district_code')) {
                  fetchWards(1, 10, {
                    search: `name=${value}`,
                    district_code: getValues('district_code'),
                  })
                }
              }}
              handleNextPage={() => {
                handleNextPage(
                  wardList?.metadata?.total_pages,
                  wardList?.metadata?.page_index,
                  wardList?.metadata.page_size,
                  fetchWards,
                  { district_code: getValues('district_code') }
                )
              }}
              required
            >
              {wardList?.wards?.map((ward: ICommonAddress) => (
                <Option key={ward?.code} value={ward?.code}>
                  {ward?.name_with_type}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
        </div>
        <div className='col-12'>
          <WarningText className='mb-8 mt-0' title='Địa chỉ cơ sở'>
            <ul>
              <li className='sapp-content-alert mt-2'>Chỉ nhập số nhà, tên đường</li>
            </ul>
          </WarningText>
        </div>
        <div className='col-12 mb-8'>
          <HookFormTextField
            control={control}
            name='address'
            label='Address Detail'
            placeholder='Address Detail'
            required
          />
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default NewArea
