import { useNavigate, useParams } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { FORMAT_DATETIME, HEADER_ENTRANCE_TEST_LIST } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { cleanParamsAPI, convertString, convertTime } from 'src/utils'
import { Link } from 'react-router-dom'
import { IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import ActionCell from 'src/components/base/action/ActionCell'
import toast from 'react-hot-toast'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { format } from 'date-fns'
import { ICommonProp } from 'src/type/common'
import { EventTestAPI } from 'src/apis/event-test'
import './ParticipantEventTable.scss'
import { isNumber } from 'lodash'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'

interface IQueryParams {
  text: string
  status?: string
  level: string
  source: string
  sortType: string
  // fromDate: Date
  // toDate: Date
  sortByRatioScore: string

  page_index: number
  page_size: number
}

export type IParams = (
  text: string,
  status: string,
  level: string,
  source: string,
  sortType: string,
  sortByRatioScore: string,
  // fromDate?: string | Date,
  // toDate?: string | Date,
  dateField?: string
) => {
  text: string
  status: string
  level: string
  source: string
  sortType: string
  sortByRatioScore: string
  // fromDate?: string | Date
  // toDate?: string | Date
  dateField?: string
}

interface IProps extends ICommonProp {
  participantList?: IParticipantInTestList
  fetchParticipantList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  getParams: IParams
  queryParams: IQueryParams
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Name',
    className: 'min-w-175px pe-5',
  },
  {
    label: 'Email',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Phone',
    className: 'min-w-125px pe-5',
  },
  {
    label: 'Multiple Choice Score',
    className: 'min-w-200px pe-5 text-center',
  },
  {
    label: 'Test Time',
    className: 'min-w-125px pe-5  text-center',
  },
  {
    label: 'Submission Time',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Source',
    className: 'max-w-100px pe-5',
  },
]

const ParticipantEventTable = ({
  participantList,
  loading,
  setLoading,
  fetchParticipantList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  // const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  // const dateQueryToDate = getDateInfo(queryParams.toDate)
  const showQueryParams =
    queryParams.text ||
    queryParams.status ||
    queryParams.level ||
    queryParams.source ||
    queryParams.sortType ||
    queryParams.sortByRatioScore
  // queryParams.fromDate ||
  // queryParams.toDate

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.text.trim() ?? '',
      queryParams.status ?? '',
      queryParams.level ?? '',
      queryParams.source ?? '',
      queryParams.sortType ?? '',
      queryParams.sortByRatioScore ?? '',
      // queryParams.fromDate
      //   ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
      //   : '',
      // queryParams.toDate
      //   ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
      //   : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  /**
   * Handle update params in url
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      text: queryParams.text ?? '',
      status: queryParams.status ?? '',
      level: queryParams.level ?? '',
      source: queryParams.source ?? '',
      sortType: queryParams.sortType ?? '',
      sortByRatioScore: queryParams.sortByRatioScore ?? '',
      // fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      // toDate: formatDate(queryParams.toDate as Date) ?? '',
      page_index: currenPage,
      page_size: size,
      dateField: 'updated_at',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  /**
   * Handle pagination
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchParticipantList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * Handle delete participant
   */
  const handleDelete = async (participant: IParticipantInTest) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        try {
          // if (participant?.quiz_attempts?.length) {
          //   toast.error('Student Finished The Test!')
          //   return
          // }
          setLoading(true)
          const res = await EventTestAPI.deleteParticipant(id, { user_ids: [participant.id] })
          if (res?.success) {
            fetchParticipantList(1, 10)
            toast.success('Delete The Participant Successfully!')
          }
        } finally {
          setLoading(false)
        }
      },
    })
  }

  const { profileMe } = useUserContext()
  const allowRenderRemoveStudentEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.REMOVE_STUDENT_EVENT_TEST
  )

  const allowRenderGetEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST
  )
  const allowRenderCreateEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST
  )
  const allowRenderGetEventParticipant = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST_PARTICIPANTS
  )

  const allowRenderEditEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST
  )

  return (
    <div className='card-body py-4'>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={participantList?.data}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_ENTRANCE_TEST_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_ENTRANCE_TEST_LIST} />
            ))}
          </>
        ) : (
          <>
            {participantList?.data?.map((participant: IParticipantInTest, index: number) => {
              const submitTime = participant?.quiz_attempts?.[0]?.finished_at
                ? format(new Date(participant?.quiz_attempts?.[0]?.finished_at), FORMAT_DATETIME)
                : '--'
              return (
                <tr key={participant.id}>
                  <td>
                    {index +
                      1 +
                      (participantList?.meta?.page_index - 1) * participantList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                      to={`/student/profile/${participant.id}`}
                    >
                      {participant?.detail?.full_name}
                    </Link>
                  </td>
                  <td className='text-start'>{participant?.user_contacts?.[0]?.email}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {participant?.user_contacts?.[0]?.phone}
                    </div>
                  </td>
                  <td className='text-center'>
                    {isNumber(participant?.quiz_attempts?.[0]?.multiple_choice_score) ? (
                      <div
                        onClick={() =>
                          allowRenderGetEventTest ||
                          allowRenderGetEventParticipant ||
                          allowRenderCreateEventTest ||
                          allowRenderEditEventTest
                            ? navigate(
                                `/event-test/participants-list/result/${participant?.quiz_attempts?.[0]?.id}`
                              )
                            : {}
                        }
                        className={`sapp-text-truncate-1 ${
                          isNumber(participant?.quiz_attempts?.[0]?.multiple_choice_score)
                            ? 'text-decoration-underline cursor-pointer text-state-success'
                            : ''
                        }`}
                      >
                        {participant?.quiz_attempts?.[0]?.multiple_choice_score}/
                        {participantList?.quiz?.multiple_choice_multiplier}
                      </div>
                    ) : (
                      '--'
                    )}
                  </td>
                  <td className='text-center'>
                    {participant?.quiz_attempts?.[0]?.finished_at
                      ? convertTime(
                          participant?.quiz_attempts?.[0]?.started_at,
                          participant?.quiz_attempts?.[0]?.finished_at
                        )
                      : '--'}
                  </td>
                  <td className='text-start'>{submitTime}</td>
                  <td className='text-start'>
                    {convertString(participant?.quiz_user_instances?.[0]?.source)}
                  </td>
                  <td className='text-end bg-white sapp-absolute-column'>
                    {allowRenderRemoveStudentEventTest && (
                      <ActionCell>
                        <div
                          className={`menu-item px-3 `}
                          onClick={() => handleDelete(participant)}
                        >
                          <div className='menu-link px-3'>Delete</div>
                        </div>
                      </ActionCell>
                    )}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={participantList?.meta?.page_index || 1}
        pageSize={participantList?.meta?.page_size || 10}
        totalItems={participantList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default ParticipantEventTable
