import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import ButtonIconOnly from '../base/button/ButtonIconOnly'
import SappTable from '../base/SappTable'
import { useParams } from 'react-router-dom'
import { MOCKUP_HEADER } from 'src/constants'
import LoadingTable from 'src/common/LoadingTable'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_SUPPORTER_GROUP_GR } from 'src/constants/permission'

interface IProps {
  data?: any
  action: any
  currentPage: number
  pageSize: number
  loading: boolean
  disabled?: boolean
  allowRenderEdit?: boolean
  isId?: boolean
}

const CardMember = ({
  data,
  action,
  currentPage,
  pageSize,
  loading,
  disabled,
  allowRenderEdit,
  isId = true,
}: IProps) => {
  const headers = [
    {
      label: '#',
      className: 'w-50px',
    },
    {
      label: 'List Member',
    },
  ]

  if (allowRenderEdit) {
    headers.push({
      label: 'Action',
      className: 'd-flex justify-content-end',
    })
  }
  const { id } = useParams()

  const { profileMe } = useUserContext()

  const allowRenderCreateSupportGroup = hasPermission(
    profileMe?.roles,
    TITLE_SUPPORTER_GROUP_GR.CREATE_SUPPORTER_GROUP
  )
  const allowRenderEditSupportGroup = hasPermission(
    profileMe?.roles,
    TITLE_SUPPORTER_GROUP_GR.EDIT_SUPPORTER_GROUP
  )
  const allowRenderGetSupportGroup = hasPermission(
    profileMe?.roles,
    TITLE_SUPPORTER_GROUP_GR.GET_SUPPORTER_GROUPS
  )
  const allowRenderRemoveSupportGroup = hasPermission(
    profileMe?.roles,
    TITLE_SUPPORTER_GROUP_GR.REMOVE_SUPPORTER_GROUP
  )

  return (
    <>
      <SappTable
        hasCheck={false}
        showHashtag={false}
        headers={headers}
        loading={loading}
        isCheckedAll={false}
        data={data}
        onChange={() => {}}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {data?.map((data: any, index: number) => {
              const startIndex = (currentPage - 1) * pageSize
              const email = data?.user_contacts?.find(
                (user: any) => user?.is_default === true
              )?.email
              const emailGroup =
                data?.user?.user_contacts?.[0]?.email ?? data?.user_contacts?.[0]?.email
              return (
                <tr key={data.id}>
                  <td>{index + startIndex + 1}</td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <img
                        className='w-50px h-50px me-3 mb-0 rounded-circle'
                        src={
                          data?.detail?.avatar?.['50x50'] ??
                          data?.user?.detail?.avatar?.['50x50'] ??
                          avatar
                        }
                        alt=''
                      />
                      <span>
                        <p className='block p-0 m-0 sapp-text--default'>
                          {data?.detail?.full_name || data?.user?.detail?.full_name}
                        </p>
                        <p className='block p-0 m-0 text-gray-500 fs-7'>{emailGroup || email}</p>
                      </span>
                    </div>
                  </td>
                  <td className='d-flex justify-content-end border-0 align-items-center sapp-h-80px'>
                    {action && allowRenderEdit && allowRenderEditSupportGroup && (
                      <ButtonIconOnly
                        iconName='trash'
                        onClick={() => action(id && isId ? data.user_id : data?.id)}
                        iconType='outline'
                        bg={'gray-100'}
                        activeColor='danger'
                        iconGrayColor={'600'}
                        disabled={disabled}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
          </>
        )}
        {/* <PagiantionSAPP
          currentPage={currentPage}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          totalItems={20}
        /> */}
      </SappTable>
    </>
  )
}
export default CardMember
