import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isEmpty, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import HeaderTab from 'src/components/base/HeaderTab'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import {
  CLASSROOM_TYPE,
  FACILITY_STATUS,
  FACILITY_STATUS_ENUM,
  VALIDATION_FIELD,
} from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { useConfirm } from 'src/hooks/use-confirm'
import { IArea, IAreaList, IRoom } from 'src/type/area'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  loading: boolean
  roomDetail: IRoom | undefined
  refetch: () => void
}

interface IInputProps {
  name: string
  code: string
  capacity: string
  deferred_student: string
  new_student: string
  type: string
  facility_id: string
  address?: string
  status: string
}

const RoomProfileSetting = ({ loading, roomDetail, refetch }: IProps) => {
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const isCheckEnded = roomDetail?.status === 'ENDED' ? true : false
  const [isLoading, setIsLoading] = useState<boolean>()
  const [areaList, setAreaList] = useState<IAreaList>()

  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    code: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    capacity: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD })
      .transform((val) => parseInt(val)),
    deferred_student: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD })
      .transform((val) => parseInt(val)),
    new_student: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD })
      .transform((val) => parseInt(val)),
    type: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    facility_id: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    address: z.string({ required_error: VALIDATION_FIELD }).optional(),
    status: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  })

  const fetchArea = async (page_index: number, page_size: number, params?: Object) => {
    try {
      let addedParams =
        roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE
          ? {}
          : { status: FACILITY_STATUS_ENUM.ACTIVE }
      const res = await ClassroomApi.getAreaClasses({
        page_index,
        page_size,
        params: Object.assign(addedParams, params),
      })
      setAreaList((prev: IAreaList | undefined) => {
        return {
          metadata: res.data.metadata,
          facilities:
            res.data.metadata.page_index === 1
              ? res.data.facilities
              : [...(prev?.facilities ?? []), ...res.data.facilities]?.filter(
                  (item, index, self) => index === self.findIndex((t) => t.id === item.id)
                ),
        }
      })
    } catch (error) {}
  }

  const { handleSubmit, control, setError, clearErrors, setValue } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      capacity: '',
      deferred_student: '',
      new_student: '',
      type: '',
      facility_id: '',
      address: '',
      status: '',
    },
  })

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  const initData = async () => {
    if (!isEmpty(id) && !isUndefined(roomDetail)) {
      setValue('name', roomDetail?.name ?? '')
      setValue('code', roomDetail?.code ?? '')
      setValue('address', roomDetail?.address ?? '')
      setValue('facility_id', roomDetail?.facility_id ?? '')
      setValue('capacity', roomDetail?.capacity.toString() ?? '')
      setValue('deferred_student', roomDetail?.deferred_student.toString() ?? '')
      setValue('new_student', roomDetail?.new_student.toString() ?? '')
      setValue('type', roomDetail?.type ?? '')
      setValue('status', roomDetail?.status ?? '')
      setAreaList({
        metadata: { page_index: 1, page_size: 1, total_pages: 0, total_records: 0 },
        facilities: [roomDetail?.facility] as Array<any>,
      })
    }
  }

  const handleCancel = () => {
    refetch()
    initData()
    clearErrors()
  }

  const onSubmit: SubmitHandler<IInputProps> = async (data: IInputProps) => {
    try {
      if (data.deferred_student + data.new_student !== data.capacity) {
        setError('deferred_student', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        setError('new_student', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        setError('capacity', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        return
      } else {
        clearErrors('deferred_student')
        clearErrors('new_student')
        clearErrors('capacity')
      }
      setIsLoading(true)
      if (!id) return
      await ClassroomApi.editRoom(id, data)
      refetch()
      toast.success('Update Successfully!')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initData()
  }, [id, roomDetail])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title={TITLE_OPTIONS_AREA.editFacility} />
        <div className='collapse show'>
          <div className='card-body card-body px-10 pt-8 pb-4 row'>
            <div className='mb-8 col-12'>
              <HookFormTextField
                required
                control={control}
                name='name'
                placeholder='Name'
                disabled={isCheckEnded}
                label='Name'
              />
            </div>
            <div className='col-12 row mb-8'>
              <div className='col-6'>
                <HookFormSelectAntd
                  control={control}
                  name='facility_id'
                  placeholder='Facility'
                  label='Facility'
                  required
                  disabled={!!id}
                  onChange={(value) => {
                    if (!value) {
                      fetchArea(1, 10)
                    }
                  }}
                  onSearch={(value) => {
                    if (value) {
                      fetchArea(1, 10, { search: `name=${value},code=${value}` })
                    } else {
                      fetchArea(1, 10)
                    }
                  }}
                  handleNextPage={handleNextPage(
                    areaList?.metadata.total_pages,
                    areaList?.metadata.page_index,
                    areaList?.metadata.page_size,
                    fetchArea
                  )}
                  showSearch
                  onFocus={() => fetchArea(1, 10)}
                >
                  {areaList?.facilities?.map((area: IArea) => (
                    <Option key={area?.id} value={area.id}>
                      {area.name}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-6'>
                <HookFormTextField
                  required
                  control={control}
                  name='code'
                  placeholder='Code'
                  disabled={!!id}
                  label='Code'
                />
              </div>
            </div>
            <div className='col-12'>
              <WarningText className='mb-8 mt-0' title='Địa chỉ phòng học'>
                <ul>
                  <li className='sapp-content-alert mt-2'>
                    Người dùng chỉ cần nhập số tầng (Ví dụ: Tầng 5)
                  </li>
                </ul>
              </WarningText>
            </div>
            <div className='mb-8 col-12 row'>
              <div className='col-6'>
                <HookFormTextField
                  control={control}
                  name='address'
                  placeholder='Address'
                  label='Address'
                />
              </div>
              <div className='col-6'>
                <HookFormSelectAntd
                  control={control}
                  name='type'
                  placeholder='Type'
                  label='Type'
                  required
                  showSearch
                >
                  {CLASSROOM_TYPE?.map((status: { value: string; label: string }) => (
                    <Option key={status?.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </div>
            <div className='mb-8 col-12 row'>
              <div className='col-6'>
                <HookFormSelectAntd
                  control={control}
                  name='status'
                  placeholder='Status'
                  disabled={roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
                  label='Status'
                  required
                  showSearch
                >
                  {FACILITY_STATUS?.map((status: { label: string; value: string }) => (
                    <Option key={status?.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-6'>
                <HookFormTextField
                  required
                  control={control}
                  name='capacity'
                  placeholder='Capacity'
                  disabled={isCheckEnded}
                  label='Capacity'
                />
              </div>
            </div>
            <div className='mb-8 col-12 row'>
              <div className='col-6'>
                <HookFormTextField
                  required
                  control={control}
                  name='deferred_student'
                  placeholder='Deferred Students'
                  disabled={isCheckEnded}
                  label='Deferred Students'
                />
              </div>
              <div className='col-6'>
                <HookFormTextField
                  required
                  control={control}
                  name='new_student'
                  placeholder='New Students'
                  disabled={isCheckEnded}
                  label='New Students'
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <ButtonSecondary type='button' title='Cancel' className='me-5' onClick={handleCancel} />
            <ButtonPrimary
              type='submit'
              title='Save'
              disabled={roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
              loading={loading || isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RoomProfileSetting
